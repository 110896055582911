import { makeStyles } from '@material-ui/core';

import { backgroundImg } from 'assets/images';

export default makeStyles((theme) => ({
  root: {
    backgroundRepeat: 'no-repeat',
    display: 'flex',
    height: '100%',
    overflow: 'hidden',
    width: '100%',
  },
  root_iframe: {
    backgroundColor: theme.palette.primary.main,
  },
  root_self: {
    [theme.breakpoints.up('sm')]: {
      backgroundImage: `url(${backgroundImg})`,
    },
  },
  wrapper: {
    display: 'flex',
    flex: '1 1 auto',
    overflow: 'hidden',
  },
  wrapper_noiframe: {
    paddingTop: 64,
  },
  contentContainer: {
    display: 'flex',
    flex: '1 1 auto',
    overflow: 'hidden',
    backgroundColor: '#EEF6F8',
  },
  content: {
    flex: '1 1 auto',
    height: '100%',
    overflow: 'auto',
  },
}));
