import { useHistory } from 'react-router-dom';
import clsx from 'clsx';
import { node } from 'prop-types';

import { STEP } from 'views/Request/Common/constants';

import useStyles from './styles';
import TopBar from './TopBar';
import { useEffect, useState } from 'react';
import { PEOPLE_TYPE } from 'views/Request/Home/constants';
import { getUserInfo } from 'services/dynamicore/processes';

const parentRoute = '/request';

function RequestLayout(props) {
  const history = useHistory();
  const classes = useStyles();
  const [userInfo, setUserInfo] = useState();

  const { location: { pathname, search } } = history;
  const onIframe = window.self !== window.top;

  const handleBack = function () {
    const backRoute = {
      [STEP.CONFIRM]: `/${STEP.IDENTITY}`,
      [STEP.IDENTITY]: `/${STEP.CUSTOMER}`,
      [STEP.DOCUMENTATION]: `/${STEP.CONFIRM}`,
      [STEP.LOAN]: userInfo?.data?.people?.pii?.type === PEOPLE_TYPE.INDIVIDUAL ? `/${STEP.DOCUMENTATION}` : `/${STEP.CONFIRM}`,
      [STEP.REGISTER]: `/${STEP.SIMULATOR_RESULTS}${search}`,
      [STEP.SIMULATOR_RESULTS]: search,
    }[pathname.replace(/^\/request\//, '')];

    return backRoute
      ? function () {
        history.push(`${parentRoute}${backRoute}`);
      }
      : undefined;
  };

  useEffect(() => {
    const fetchUserInfo = async () => {
      const objUserInfo = await getUserInfo('people');
      setUserInfo(objUserInfo);
    };

    if(pathname.replace(/^\/request\//, '') !== '/request') fetchUserInfo();
  }, []); 

  return (
    <div className={clsx(classes.root, onIframe ? classes.root_iframe : classes.root_self)}>

      {!onIframe && (
        <TopBar
          onIframe={onIframe}
          handleBack={handleBack()}
        />
      )}

      <div className={clsx(classes.root, !onIframe && classes.wrapper_noiframe)}>
        <div className={classes.contentContainer}>
          <div
            children={props.children}
            className={classes.content}
          />
        </div>
      </div>

    </div>
  );
}

RequestLayout.propTypes = {
  children: node,
};

export default RequestLayout;
