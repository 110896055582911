import { getSettings } from 'utils/settings';

const registerBy = getSettings('modules.auth.registerBy');

export const STEP = {
  CONFIRM: 'confirm',
  CUSTOMER: 'customer',
  DOCUMENTATION: 'documentation',
  FINISH: 'finish',
  IDENTITY: 'identity',
  IDENTITY_ID_FRONT: 'identity_id_front',
  IDENTITY_ID_BACK: 'identity_id_back',
  IDENTITY_SELFIE: 'identity_selfie',
  IDENTITY_PENDING: 'identity_pending',
  IDENTITY_ERROR: 'identity_error',
  LOAN: 'loan',
  REGISTER: 'register',
  REGISTER_CONFIRM: 'register_confirmation',
  SIMULATOR: 'simulator',
  SIMULATOR_RESULTS: 'simulator_results',
};

export const STEPS = [
  {
    description: 'Ofrecemos planes flexibles para todas tus necesidades.',
    header: 'Calcula tu financiamiento',
    name: STEP.SIMULATOR,
    title: 'Simulador',
  },
  {
    header: 'Resultados de la simulación',
    name: STEP.SIMULATOR_RESULTS,
    title: 'Simulador',
  },
  {
    description: 'Realizar el registro de tu cuenta para continuar con la solicitud de crédito',
    header: 'Crear una cuenta',
    name: STEP.REGISTER,
    title: 'Registro',
  },
  {
    description: `Ingresa el código que te enviamos al ${registerBy === 'phone_number'
      ? 'número'
      : 'correo electrónico'}`,
    header: `Verifica tu ${registerBy === 'phone_number'
      ? 'número'
      : 'correo electrónico'}`,
    name: STEP.REGISTER_CONFIRM,
    title: 'Registro',
  },
  {
    header: 'Queremos conocerte mejor',
    name: STEP.CUSTOMER,
    title: 'Cliente',
  },
  {
    description: 'Para continuar necesitaras validar tu identidad desde tu teléfono',
    header: 'Valida tu identidad para continuar',
    name: STEP.IDENTITY,
    title: 'Identidad',
  },
  {
    description: 'Muestra el frente de la identificación',
    header: 'Frente de tu identificación',
    name: STEP.IDENTITY_ID_FRONT,
    title: 'Identidad',
  },
  {
    description: 'Muestra la parte trasera de la identificación',
    header: 'Reverso de tu identificación',
    name: STEP.IDENTITY_ID_BACK,
    title: 'Identidad',
  },
  {
    description: 'Tu rostro debe estar sin gorras, lentes o audífonos',
    header: 'Captura tu rostro',
    name: STEP.IDENTITY_SELFIE,
    title: 'Identidad',
  },
  {
    description: 'Esto tomará solo algunos segundos...',
    header: 'Estamos analizando tu información',
    name: STEP.IDENTITY_PENDING,
    title: 'Identidad',
  },
  {
    description: 'Tuvimos algunos problemas con la verificación de tu documento',
    header: 'Error al validar tu identidad',
    name: STEP.IDENTITY_ERROR,
    title: 'Identidad',
  },
  {
    description: 'Verifica que tu información este de manera correcta',
    header: 'Confirma tus datos',
    name: STEP.CONFIRM,
    title: 'Confirmación',
  },
  {
    description: 'Verifica que tu información este de manera correcta',
    header: 'Sube los siguientes documentos',
    name: STEP.DOCUMENTATION,
    title: 'Documentación',
  },
  {
    description: 'Confirma los datos de tu próximo crédito',
    name: STEP.LOAN,
    title: 'Crédito',
  },
  {
    description: 'Te invitamos a descargar nuestra aplicación para poder aceptar tú crédito',
    header: '¡Último paso!',
    name: STEP.FINISH,
    title: 'Finalizar',
  },
];
