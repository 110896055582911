import {
  Box, makeStyles, Typography,
} from '@material-ui/core';
import { number, string } from 'prop-types';
import {
  loaderLogoImg,
  loaderLogoWImg,
  loaderLogoCircleImg,
  loaderLogoCircleWImg,
} from 'assets/images';

const onIframe = false;

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'inline-block',
    position: 'relative',
    textAlign: 'center',
  },
  background: {
    backgroundImage: `url(${onIframe ? loaderLogoWImg : loaderLogoImg})`,
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat',
    backgroundSize: '60%',
    height: '100%',
    position: 'absolute',
    width: '100%',
  },
  header_description: {
    color: '#282828',
    fontWeight: 400,
    letterSpacing: 0.24,
    fontFamily: 'Outfit-Regular',
  },
  header_description_iframe: {
    color: '#282828',
    fontFamily: 'Outfit-Regular',
  },
  image: {
    animation: '$spin 5s linear infinite',
  },
  '@keyframes spin': {
    from: {
      transform: 'rotate(0deg)',
    },
    to: {
      transform: 'rotate(360deg)',
    },
  },
}));

function LoaderComponent({ width, text }) {
  const classes = useStyles();

  return (
    <div
      className={classes.root}
      style={{
        width: `${width}px`,
        height: `${width}px`,
      }}
    >
      <div className={classes.background} />

      <img
        alt="Loader"
        className={classes.image}
        src={onIframe ? loaderLogoCircleWImg : loaderLogoCircleImg}
        width={`${width}px`}
      />
      {text &&
        <Box mt={10}>
          <Typography
            align="center"
            children={text}
            className={onIframe
              ? classes.header_description_iframe
              : classes.header_description}
            variant="body1"
          />
        </Box>
      }
    </div>
  );
}

LoaderComponent.defaultProps = {
  width: 180,
  text: '',
};

LoaderComponent.propTypes = {
  width: number,
  text: string,
};

export default LoaderComponent;
