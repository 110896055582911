export const PEOPLE_TYPE = {
  INDIVIDUAL: 'individual',
  INDIVIDUAL_BUSINESS: 'individual_business',
  LEGAL: 'legal',
};

export const PEOPLE_TYPES = [
  {
    amountRange: [5000, 90000],
    label: 'Persona física',
    value: PEOPLE_TYPE.INDIVIDUAL,
  },
  {
    amountRange: [5000, 1000000],
    annualBilling: [
      {
        id: 1,
        name: 'De $ 0.00 - $ 500,000.00',
      },
      {
        id: 2,
        name: 'De $ 500,000.00 - $ 1,000,000.00',
      },
      {
        id: 3,
        name: 'De $ 1,000,000.00 - $ 2,000,000.00',
      },
      {
        id: 4,
        name: 'De $ 2,000,000 - $ 5,000,000.00',
      },
      {
        id: 5,
        name: 'Mayor a $ 5,000,000.00',
      },
    ],
    label: 'Física con act. empre.',
    value: PEOPLE_TYPE.INDIVIDUAL_BUSINESS,
  },
  {
    amountRange: [5000, 1000000],
    annualBilling: [
      {
        id: 1,
        name: 'De $ 0.00 - $ 500,000.00',
      },
      {
        id: 2,
        name: 'De $ 500,000.00 - $ 1,000,000.00',
      },
      {
        id: 3,
        name: 'De $ 1,000,000.00 - $ 2,000,000.00',
      },
      {
        id: 4,
        name: 'De $ 2,000,000 - $ 5,000,000.00',
      },
      {
        id: 5,
        name: 'Mayor a $ 5,000,000.00',
      },
    ],
    label: 'Persona moral',
    value: PEOPLE_TYPE.LEGAL,
  },
];

export const SERVICE_TYPES = [
  {
    id: '111013',
    name: 'cultivo de alpiste',
  },
  {
    id: '111021',
    name: 'cultivo de arroz',
  },
  {
    id: '111039',
    name: 'cultivo de avena',
  },
  {
    id: '111047',
    name: 'cultivo de cebada',
  },
  {
    id: '111055',
    name: 'cultivo de linaza',
  },
  {
    id: '111063',
    name: 'cultivo de maiz',
  },
  {
    id: '111071',
    name: 'cultivo de milo',
  },
  {
    id: '111089',
    name: 'cultivo de sorgo',
  },
  {
    id: '111097',
    name: 'cultivo de soya',
  },
  {
    id: '111104',
    name: 'cultivo de trigo',
  },
  {
    id: '112011',
    name: 'cultivo de ajo',
  },
  {
    id: '112029',
    name: 'cultivo de calabaza',
  },
  {
    id: '112037',
    name: 'cultivo de camote',
  },
  {
    id: '112045',
    name: 'cultivo de cebolla',
  },
  {
    id: '112053',
    name: 'cultivo de chile',
  },
  {
    id: '112061',
    name: 'cultivo de esparrago',
  },
  {
    id: '112079',
    name: 'cultivo de frijol',
  },
  {
    id: '112087',
    name: 'cultivo de garbanzo',
  },
  {
    id: '112095',
    name: 'cultivo de jitomate',
  },
  {
    id: '112102',
    name: 'cultivo de lenteja',
  },
  {
    id: '112110',
    name: 'cultivo de nopal',
  },
  {
    id: '112128',
    name: 'cultivo de otras hortalizas',
  },
  {
    id: '112136',
    name: 'cultivo de papa',
  },
  {
    id: '112144',
    name: 'cultivo de remolacha',
  },
  {
    id: '112152',
    name: 'cultivo de tomate',
  },
  {
    id: '112160',
    name: 'cultivo de yuca',
  },
  {
    id: '113019',
    name: 'cultivo de alfalfa',
  },
  {
    id: '119017',
    name: 'cultivo de fresa',
  },
  {
    id: '119025',
    name: 'cultivo de melon',
  },
  {
    id: '119033',
    name: 'cultivo de piða',
  },
  {
    id: '119041',
    name: 'cultivo de sandia',
  },
  {
    id: '121012',
    name: 'cultivo de algodon',
  },
  {
    id: '122010',
    name: 'cultivo de caða de azucar',
  },
  {
    id: '123018',
    name: 'cultivo de tabaco',
  },
  {
    id: '124016',
    name: 'cultivo de ajonjoli',
  },
  {
    id: '124024',
    name: 'cultivo de cacahuate',
  },
  {
    id: '124032',
    name: 'cultivo de cartamo',
  },
  {
    id: '124040',
    name: 'cultivo de nuez',
  },
  {
    id: '124058',
    name: 'cultivo de olivo',
  },
  {
    id: '129016',
    name: 'cultivo de especias',
  },
  {
    id: '129024',
    name: 'cultivo de otras semillas',
  },
  {
    id: '129032',
    name: 'cultivo de semillas mejoradas',
  },
  {
    id: '129040',
    name: 'cultivo de vainilla',
  },
  {
    id: '131011',
    name: 'cultivo de flores y plantas de ornato',
  },
  {
    id: '141010',
    name: 'cultivo de cafe',
  },
  {
    id: '142018',
    name: 'cultivo de ahuacate',
  },
  {
    id: '142026',
    name: 'cultivo de durazno',
  },
  {
    id: '142034',
    name: 'cultivo de guayaba',
  },
  {
    id: '142042',
    name: 'cultivo de limon',
  },
  {
    id: '142050',
    name: 'cultivo de mango',
  },
  {
    id: '142068',
    name: 'cultivo de manzana',
  },
  {
    id: '142076',
    name: 'cultivo de naranja',
  },
  {
    id: '142084',
    name: 'cultivo de otros arboles frutales',
  },
  {
    id: '142092',
    name: 'cultivo de papaya',
  },
  {
    id: '142109',
    name: 'cultivo de platano',
  },
  {
    id: '142117',
    name: 'cultivo de tamarindo',
  },
  {
    id: '142125',
    name: 'cultivo de toronja',
  },
  {
    id: '143016',
    name: 'cultivo de vid',
  },
  {
    id: '144014',
    name: 'cultivo de henequen',
  },
  {
    id: '144022',
    name: 'cultivo de lino',
  },
  {
    id: '144030',
    name: 'cultivo de otras fibras duras',
  },
  {
    id: '145012',
    name: 'cultivo de agave o mezcal',
  },
  {
    id: '145020',
    name: 'cultivo de maguey',
  },
  {
    id: '146010',
    name: 'cultivo de copra',
  },
  {
    id: '147018',
    name: 'cultivo de cacao',
  },
  {
    id: '149014',
    name: 'otros cultivos permanentes',
  },
  {
    id: '149907',
    name: 'usuarios menores de agricultura',
  },
  {
    id: '149915',
    name: 'cartera agricola de estados analiticos',
  },
  {
    id: '191015',
    name: 'irrigacion de tierras',
  },
  {
    id: '191023',
    name: 'preparacion de tierras de cultivo y otros servicio',
  },
  {
    id: '211011',
    name: 'cria y explotacion de ganado vacuno para carne',
  },
  {
    id: '212019',
    name: 'cria y explotacion de ganado vacuno para leche',
  },
  {
    id: '213017',
    name: 'cria de ganado de lidia',
  },
  {
    id: '214015',
    name: 'cria de ganado caballar',
  },
  {
    id: '219015',
    name: 'cria de otros equinos y ganado para el trabajo',
  },
  {
    id: '219908',
    name: 'usuarios menores de ganaderia',
  },
  {
    id: '219916',
    name: 'cartera ganadera de estados analiticos',
  },
  {
    id: '221010',
    name: 'cria y explotacion de ganado porcino',
  },
  {
    id: '222018',
    name: 'cria y explotacion de ganado ovino',
  },
  {
    id: '223016',
    name: 'cria y explotacion de ganado caprino',
  },
  {
    id: '231019',
    name: 'cria y explotacion de gallina para produccion de h',
  },
  {
    id: '232017',
    name: 'cria y explotacion de pollos',
  },
  {
    id: '233015',
    name: 'cria y explotacion de otras aves para alimentacion',
  },
  {
    id: '241018',
    name: 'cria y explotacion de abejas',
  },
  {
    id: '251017',
    name: 'cria y explotacion de conejos y liebres',
  },
  {
    id: '259011',
    name: 'cria y explotacion',
  },
  {
    id: '259029',
    name: 'cria y explotacion de gusano de seda',
  },
  {
    id: '291013',
    name: 'formacion de praderas y potreros artificiales',
  },
  {
    id: '291021',
    name: 'inseminacion artificial y otros servicios de ganad',
  },
  {
    id: '311019',
    name: 'plantacion y reforestacion',
  },
  {
    id: '312017',
    name: 'extraccion de troncos para aserraderos y para pulp',
  },
  {
    id: '313015',
    name: 'extraccion de carbon vegetal',
  },
  {
    id: '321018',
    name: 'extraccion de chicle',
  },
  {
    id: '322016',
    name: 'explotacion de candelilla',
  },
  {
    id: '322024',
    name: 'explotacion de hule',
  },
  {
    id: '322032',
    name: 'extraccion de colofonia',
  },
  {
    id: '322040',
    name: 'extraccion de otras resinas',
  },
  {
    id: '323014',
    name: 'explotacion de raices',
  },
  {
    id: '323022',
    name: 'extraccion de alquitran vegetal',
  },
  {
    id: '323030',
    name: 'extraccion de trementina',
  },
  {
    id: '329012',
    name: 'cultivo y explotacion de palma y lechuguilla',
  },
  {
    id: '329020',
    name: 'explotacion de barbasco,arborescentes y arbustos',
  },
  {
    id: '329905',
    name: 'usuarios menores de silvicultura, pesca y preserva',
  },
  {
    id: '329913',
    name: 'cartera silvicola de estados analiticos',
  },
  {
    id: '391011',
    name: 'servicios de cortado estimacion del volumen de mad',
  },
  {
    id: '411017',
    name: 'captura de atun bonito barrilete y similares',
  },
  {
    id: '412015',
    name: 'captura de sardina y similares',
  },
  {
    id: '413013',
    name: 'captura de tiburon cazon raya y similares',
  },
  {
    id: '419011',
    name: 'captura de otros peces en estuarios, costas o alta',
  },
  {
    id: '419912',
    name: 'cartera pesquera de estados analiticos',
  },
  {
    id: '421016',
    name: 'captura de camaron',
  },
  {
    id: '422014',
    name: 'captura de ostion',
  },
  {
    id: '429010',
    name: 'captura de otros crustaceos y moluscos marinos',
  },
  {
    id: '431015',
    name: 'captura de tortuga y otros reptiles marinos',
  },
  {
    id: '439019',
    name: 'captura de mamiferos anfibios y diversos invertebr',
  },
  {
    id: '441014',
    name: 'recoleccion de conchas huevos corales esponjas y p',
  },
  {
    id: '442012',
    name: 'recoleccion de algas y otras plantas acuaticas',
  },
  {
    id: '451013',
    name: 'captura de peces en rios, lagos y estuarios',
  },
  {
    id: '452011',
    name: 'captura de crustaceos moluscos reptiles anfibios y',
  },
  {
    id: '459017',
    name: 'cria y explotacion de ostras',
  },
  {
    id: '459025',
    name: 'cria y explotacion de peces, otras especies animal',
  },
  {
    id: '491019',
    name: 'servicios de pesquerias maritimas y de agua dulce',
  },
  {
    id: '511015',
    name: 'captura y preservacion de especies animales salvaj',
  },
  {
    id: '1111012',
    name: 'extraccion y beneficio de carbon mineral y grafito',
  },
  {
    id: '1211010',
    name: 'exploracion de petroleo por compaðias',
  },
  {
    id: '1211028',
    name: 'extraccion de petroleo crudo y gas natural',
  },
  {
    id: '1211903',
    name: 'usuarios menores de petroleo',
  },
  {
    id: '1211911',
    name: 'cartera petrolera de estados analiticos',
  },
  {
    id: '1311018',
    name: 'extraccion y beneficio de mineral de hierro',
  },
  {
    id: '1321017',
    name: 'extraccion y beneficio de oro plata y otros metale',
  },
  {
    id: '1322015',
    name: 'extraccion y beneficio de mercurio y antimonio',
  },
  {
    id: '1329011',
    name: 'extraccion y beneficio de cobre, plomo, zinc y otr',
  },
  {
    id: '1329904',
    name: 'usuarios menores de mineria',
  },
  {
    id: '1329912',
    name: 'cartera minera de estados analiticos',
  },
  {
    id: '1411016',
    name: 'extraccion y beneficio de piedra',
  },
  {
    id: '1412014',
    name: 'extraccion de yeso',
  },
  {
    id: '1413012',
    name: 'extraccion y beneficio de arena y grava',
  },
  {
    id: '1419010',
    name: 'extraccion y beneficio de otros materiales para co',
  },
  {
    id: '1421015',
    name: 'extraccion y beneficio de arcillas refractarias',
  },
  {
    id: '1431014',
    name: 'extraccion y beneficio de barita y roca fosforica',
  },
  {
    id: '1432012',
    name: 'extraccion y beneficio de fluorita',
  },
  {
    id: '1433010',
    name: 'extraccion y beneficio de silice',
  },
  {
    id: '1439018',
    name: 'extraccion y beneficio de otros minerales no metal',
  },
  {
    id: '1511014',
    name: 'explotacion de sal marina y de yacimientos',
  },
  {
    id: '2011013',
    name: 'deshidratacion de frutas',
  },
  {
    id: '2012011',
    name: 'empacadora de conservas alimenticias',
  },
  {
    id: '2012029',
    name: 'empacadora de frutas y legumbres',
  },
  {
    id: '2012037',
    name: 'fabricacion de concentrados de frutas',
  },
  {
    id: '2012045',
    name: 'fabricacion de encurtidos',
  },
  {
    id: '2013019',
    name: 'fabricacion de ates',
  },
  {
    id: '2013027',
    name: 'fabricacion de queso y miel de tuna',
  },
  {
    id: '2014017',
    name: 'fabricacion de salsas',
  },
  {
    id: '2021012',
    name: 'molino de trigo',
  },
  {
    id: '2022010',
    name: 'molino de maiz',
  },
  {
    id: '2023018',
    name: 'molino de nixtamal',
  },
  {
    id: '2024016',
    name: 'molino de arroz',
  },
  {
    id: '2025014',
    name: 'beneficio de cafe excepto molienda y tostado',
  },
  {
    id: '2026012',
    name: 'molino y tostador de cafe',
  },
  {
    id: '2027010',
    name: 'empacadora de te',
  },
  {
    id: '2028018',
    name: 'beneficio de arroz excepto molienda',
  },
  {
    id: '2028026',
    name: 'descascarado y tostado de cacahuate y nuez',
  },
  {
    id: '2028034',
    name: 'descascaradora y tostadora de semilla de calabaza',
  },
  {
    id: '2028042',
    name: 'molino para otros granos excepto cereales',
  },
  {
    id: '2029016',
    name: 'molino de avena',
  },
  {
    id: '2029024',
    name: 'molino de cebada',
  },
  {
    id: '2029032',
    name: 'molino de otros cereales',
  },
  {
    id: '2031011',
    name: 'fabricacion de marquetas y estuchados de azucar',
  },
  {
    id: '2031029',
    name: 'ingenio azucarero',
  },
  {
    id: '2032019',
    name: 'fabricacion de piloncillo',
  },
  {
    id: '2033017',
    name: 'destilacion de alcohol etilico',
  },
  {
    id: '2041010',
    name: 'rastro',
  },
  {
    id: '2049014',
    name: 'empacadora de carne',
  },
  {
    id: '2049022',
    name: 'fabricacion de carnes frias y embutidos',
  },
  {
    id: '2049030',
    name: 'refrigeracion de carnes',
  },
  {
    id: '2051019',
    name: 'fabricacion y rehidratacion de leche',
  },
  {
    id: '2051027',
    name: 'pasteurizacion homogeneizacion y envasado de leche',
  },
  {
    id: '2052017',
    name: 'fabricacion de crema mantequilla y queso',
  },
  {
    id: '2053015',
    name: 'fabricacion de leche condensada evaporada y pulver',
  },
  {
    id: '2054013',
    name: 'fabricacion de gelatinas',
  },
  {
    id: '2054021',
    name: 'fabricacion de grenetina',
  },
  {
    id: '2059013',
    name: 'fabricacion de cajetas yogourts y otros productos',
  },
  {
    id: '2061018',
    name: 'congeladora de productos marinos',
  },
  {
    id: '2061026',
    name: 'empacadora de otros mariscos',
  },
  {
    id: '2061034',
    name: 'empacadora de pescado',
  },
  {
    id: '2071017',
    name: 'fabricacion de pan y pasteles',
  },
  {
    id: '2072015',
    name: 'fabricacion de conos para nieve',
  },
  {
    id: '2072023',
    name: 'fabricacion de galletas',
  },
  {
    id: '2072031',
    name: 'fabricacion de pastas alimenticias',
  },
  {
    id: '2081016',
    name: 'fabricacion de chocolates',
  },
  {
    id: '2082014',
    name: 'fabricacion de dulces bombones y confites',
  },
  {
    id: '2083012',
    name: 'fabricacion de goma de mascar',
  },
  {
    id: '2084010',
    name: 'tratamiento y envase de miel de abeja',
  },
  {
    id: '2089010',
    name: 'fabricacion de jarabes',
  },
  {
    id: '2091015',
    name: 'fabricacion de aceites vegetales comestibles',
  },
  {
    id: '2091023',
    name: 'fabricacion de mantecas vegetales comestibles',
  },
  {
    id: '2092013',
    name: 'fabricacion de almidon',
  },
  {
    id: '2092021',
    name: 'fabricacion de levaduras',
  },
  {
    id: '2093011',
    name: 'tortilleria',
  },
  {
    id: '2094019',
    name: 'fabricacion de frituras',
  },
  {
    id: '2094027',
    name: 'fabricacion de otros preparados alimenticios deriv',
  },
  {
    id: '2095017',
    name: 'fabricacion de vinagre',
  },
  {
    id: '2095025',
    name: 'refinacion de sal',
  },
  {
    id: '2096015',
    name: 'fabricacion de hielo',
  },
  {
    id: '2097013',
    name: 'fabricacion de helados nieves y paletas',
  },
  {
    id: '2098011',
    name: 'deshidratacion de plantas para forrajes',
  },
  {
    id: '2098029',
    name: 'fabricacion de alimento para ganado y otros animal',
  },
  {
    id: '2098037',
    name: 'fabricacion de alimentos para aves',
  },
  {
    id: '2099019',
    name: 'empacadora de especias',
  },
  {
    id: '2099027',
    name: 'fabricacion de aceites y mantecas animales comesti',
  },
  {
    id: '2099035',
    name: 'fabricacion de harina de pescado',
  },
  {
    id: '2099043',
    name: 'fabricacion de otros productos alimenticios',
  },
  {
    id: '2099051',
    name: 'fabricacion de pastas para guisos',
  },
  {
    id: '2111011',
    name: 'fabricacion de tequila y mezcal',
  },
  {
    id: '2112019',
    name: 'fabricacion de aguardiente de caða',
  },
  {
    id: '2113017',
    name: 'fabricacion de sotol',
  },
  {
    id: '2114015',
    name: 'fabricacion de otros aguardientes no de caða',
  },
  {
    id: '2114023',
    name: 'fabricacion de vinos y otros licores',
  },
  {
    id: '2115013',
    name: 'elaboracion de pulque',
  },
  {
    id: '2119015',
    name: 'fabricacion de sidra, champaða y otras bebidas fer',
  },
  {
    id: '2121010',
    name: 'fabricacion de malta',
  },
  {
    id: '2122018',
    name: 'fabricacion de cerveza',
  },
  {
    id: '2131019',
    name: 'embotellado de aguas minerales',
  },
  {
    id: '2131027',
    name: 'fabricacion de bebidas gaseosas',
  },
  {
    id: '2131035',
    name: 'fabricacion de refrescos de frutas naturales',
  },
  {
    id: '2131043',
    name: 'purificacion de agua, excepto captacion tratamient',
  },
  {
    id: '2211019',
    name: 'desecado de tabaco',
  },
  {
    id: '2212017',
    name: 'fabricacion de cigarros',
  },
  {
    id: '2219013',
    name: 'fabricacion de puros',
  },
  {
    id: '2219021',
    name: 'picado de tabaco',
  },
  {
    id: '2311017',
    name: 'desfibracion de algodon',
  },
  {
    id: '2311025',
    name: 'despepite de algodon',
  },
  {
    id: '2311033',
    name: 'compresora de algodon',
  },
  {
    id: '2312015',
    name: 'beneficio de lanas',
  },
  {
    id: '2312023',
    name: 'beneficio de otras fibras textiles',
  },
  {
    id: '2312031',
    name: 'fabricacion de hilados y tejidos de algodon',
  },
  {
    id: '2312049',
    name: 'fabricacion de hilados y tejidos de lana',
  },
  {
    id: '2313013',
    name: 'fabricacion de hilos para coser',
  },
  {
    id: '2313021',
    name: 'acabado de hilos',
  },
  {
    id: '2313039',
    name: 'fabricacion de otros hilados y tejidos no sintetic',
  },
  {
    id: '2314011',
    name: 'fabricacion de estambres',
  },
  {
    id: '2315019',
    name: 'fabricacion de sarapes y cobijas',
  },
  {
    id: '2315027',
    name: 'fabricacion de casimires y paðos',
  },
  {
    id: '2316017',
    name: 'fabricacion de toallas',
  },
  {
    id: '2316025',
    name: 'fabricacion de colchas',
  },
  {
    id: '2317015',
    name: 'fabricacion de hilados y tejidos de seda',
  },
  {
    id: '2317023',
    name: 'fabricacion de otros articulos de lana',
  },
  {
    id: '2317031',
    name: 'fabricacion de otras telas mixtas  de fibras bland',
  },
  {
    id: '2318013',
    name: 'fabricacion de cintas agujetas y listones',
  },
  {
    id: '2318021',
    name: 'fabricacion de encajes',
  },
  {
    id: '2318039',
    name: 'fabricacion de telas elasticas',
  },
  {
    id: '2319011',
    name: 'acabado de telas',
  },
  {
    id: '2321016',
    name: 'fabricacion de calcetines',
  },
  {
    id: '2321024',
    name: 'fabricacion de medias',
  },
  {
    id: '2322014',
    name: 'fabricacion de sueteres',
  },
  {
    id: '2329010',
    name: 'fabricacion de hilados y tejidos de otras fibras s',
  },
  {
    id: '2331015',
    name: 'desfibracion de henequen',
  },
  {
    id: '2332013',
    name: 'fabricacion de hilados y tejidos de henequen',
  },
  {
    id: '2333011',
    name: 'fabricacion de articulos de palma y tule',
  },
  {
    id: '2333029',
    name: 'fabricacion de tejidos y torcidos de palma',
  },
  {
    id: '2333037',
    name: 'fabricacion de tejidos y torcidos de ixtle',
  },
  {
    id: '2339019',
    name: 'fabricacion de hilados y tejidos de yute',
  },
  {
    id: '2339027',
    name: 'fabricacion de hilados y tejidos de fibra de coco',
  },
  {
    id: '2391019',
    name: 'fabricacion de articulos de lona',
  },
  {
    id: '2391027',
    name: 'fabricacion de lona',
  },
  {
    id: '2391035',
    name: 'fabricacion de telas impermeables',
  },
  {
    id: '2392017',
    name: 'fabricacion de tapetes y alfombras',
  },
  {
    id: '2392025',
    name: 'fabricacion de telas para tapiceria',
  },
  {
    id: '2393015',
    name: 'fabricacion de entretelas y fieltros',
  },
  {
    id: '2394013',
    name: 'beneficio de pelo y cerda para la industria textil',
  },
  {
    id: '2394021',
    name: 'fabricacion de borras y estopas',
  },
  {
    id: '2394039',
    name: 'fabricacion de otros articulos de algodon',
  },
  {
    id: '2411015',
    name: 'fabricacion de vestidos y otras prendas exteriores',
  },
  {
    id: '2411023',
    name: 'taller de confeccion de vestidos',
  },
  {
    id: '2412013',
    name: 'fabricacion de otras prendas exteriores de vestir',
  },
  {
    id: '2412021',
    name: 'fabricacion de trajes para caballero',
  },
  {
    id: '2412039',
    name: 'taller de sastreria',
  },
  {
    id: '2413011',
    name: 'fabricacion de uniformes',
  },
  {
    id: '2414019',
    name: 'fabricacion de camisas',
  },
  {
    id: '2415017',
    name: 'fabricacion de otras prendas exteriores de vestir',
  },
  {
    id: '2416015',
    name: 'fabricacion de corbatas',
  },
  {
    id: '2416023',
    name: 'fabricacion de guantes',
  },
  {
    id: '2416031',
    name: 'fabricacion de paðuelos paðoletas y mascadas',
  },
  {
    id: '2417013',
    name: 'fabricacion de cachuchas y gorras',
  },
  {
    id: '2417021',
    name: 'fabricacion de sombreros excepto de palma',
  },
  {
    id: '2418011',
    name: 'fabricacion de sombreros de palma',
  },
  {
    id: '2419019',
    name: 'fabricacion de chamarras y abrigos',
  },
  {
    id: '2419027',
    name: 'fabricacion de impermeables',
  },
  {
    id: '2419035',
    name: 'fabricacion de tirantes',
  },
  {
    id: '2419043',
    name: 'fabricacion de mantones y chalinas',
  },
  {
    id: '2419051',
    name: 'fabricacion de rebozos y cinturones tejidos de hil',
  },
  {
    id: '2419069',
    name: 'fabricacion de ropa con piel',
  },
  {
    id: '2421014',
    name: 'fabricacion de corseteria y ropa interior para dam',
  },
  {
    id: '2429018',
    name: 'fabricacion de ropa interior para caballero',
  },
  {
    id: '2431013',
    name: 'fabricacion de sabanas',
  },
  {
    id: '2431021',
    name: 'fabricacion de cortinas de tela y manteleria',
  },
  {
    id: '2432011',
    name: 'fabricacion de cubreasientos para vehiculos',
  },
  {
    id: '2432029',
    name: 'fabricacion de tapices plasticos',
  },
  {
    id: '2433019',
    name: 'fabricacion de algodon absorbente vendas tela adeh',
  },
  {
    id: '2434017',
    name: 'fabricacion de articulos bordados y deshilados',
  },
  {
    id: '2434025',
    name: 'forrado de botones y hebillas',
  },
  {
    id: '2439017',
    name: 'fabricacion de banderas y adornos de tela',
  },
  {
    id: '2439025',
    name: 'fabricacion de costales',
  },
  {
    id: '2511013',
    name: 'fabricacion de calzado para deporte excepto de pla',
  },
  {
    id: '2512011',
    name: 'fabricacion de alpargatas babuchas y pantuflas',
  },
  {
    id: '2512029',
    name: 'fabricacion de guaraches y sandalias',
  },
  {
    id: '2519017',
    name: 'fabricacion de calzado de cuero o piel',
  },
  {
    id: '2521012',
    name: 'curtiduria',
  },
  {
    id: '2521020',
    name: 'preparacion de visceras para industrias no aliment',
  },
  {
    id: '2529016',
    name: 'fabricacion de cepillos y plumeros',
  },
  {
    id: '2529024',
    name: 'fabricacion de articulos de cuero para viaje',
  },
  {
    id: '2529032',
    name: 'fabricacion de articulos de cuero y hueso',
  },
  {
    id: '2529040',
    name: 'fabricacion de articulos de cuero y piel para zapa',
  },
  {
    id: '2529058',
    name: 'fabricacion de articulos tejidos de cuero',
  },
  {
    id: '2529066',
    name: 'fabricacion de bandas y correas de cuero',
  },
  {
    id: '2529074',
    name: 'fabricacion de bolsas y carteras de cuero',
  },
  {
    id: '2529082',
    name: 'fabricacion de brochas y pinceles',
  },
  {
    id: '2529090',
    name: 'fabricacion de fornituras militares de cuero',
  },
  {
    id: '2529107',
    name: 'fabricacion de otros articulos de cuero',
  },
  {
    id: '2529115',
    name: 'talabarteria',
  },
  {
    id: '2611011',
    name: 'aserradero',
  },
  {
    id: '2611029',
    name: 'beneficio de maderas (desflemado estufado cepillad',
  },
  {
    id: '2612019',
    name: 'fabricacion de triplay y otros aglomerados de made',
  },
  {
    id: '2621010',
    name: 'fabricacion de cajas y empaques de madera',
  },
  {
    id: '2621028',
    name: 'fabricacion de toneles y barricas de madera',
  },
  {
    id: '2622018',
    name: 'fabricacion de articulos de palma vara carrizo mim',
  },
  {
    id: '2631019',
    name: 'fabricacion de ataudes de madera',
  },
  {
    id: '2632017',
    name: 'fabricacion de juntas y empaques de corcho',
  },
  {
    id: '2632025',
    name: 'fabricacion de otros articulos de corcho',
  },
  {
    id: '2632033',
    name: 'fabricacion de asbestos y corchos aislantes',
  },
  {
    id: '2633015',
    name: 'fabricacion de duelas y otros materiales de madera',
  },
  {
    id: '2633023',
    name: 'fabricacion de puertas y ventanas de madera',
  },
  {
    id: '2639013',
    name: 'fabricacion de carrocerias y redilas de madera',
  },
  {
    id: '2639021',
    name: 'fabricacion de mangos de madera',
  },
  {
    id: '2639039',
    name: 'fabricacion de otros articulos de madera',
  },
  {
    id: '2711019',
    name: 'fabricacion de muebles de madera',
  },
  {
    id: '2711027',
    name: 'fabricacion de muebles de material sintetico',
  },
  {
    id: '2712017',
    name: 'fabricacion de persianas de madera',
  },
  {
    id: '2713015',
    name: 'fabricacion de almohadas y cojines',
  },
  {
    id: '2713023',
    name: 'fabricacion de colchones y colchonetas',
  },
  {
    id: '2719013',
    name: 'fabricacion de marcos y molduras de madera',
  },
  {
    id: '2811017',
    name: 'fabricacion de papel',
  },
  {
    id: '2811025',
    name: 'fabricacion de papel para cigarros',
  },
  {
    id: '2811033',
    name: 'fabricacion de pasta de celulosa',
  },
  {
    id: '2811041',
    name: 'fabricacion de pasta o pulpa para papel',
  },
  {
    id: '2812015',
    name: 'fabricacion de carton',
  },
  {
    id: '2821016',
    name: 'fabricacion de sacos y bolsas de papel para envase',
  },
  {
    id: '2822014',
    name: 'fabricacion de envases de carton',
  },
  {
    id: '2829010',
    name: 'fabricacion de otros articulos de carton',
  },
  {
    id: '2829028',
    name: 'fabricacion de otros articulos de papel',
  },
  {
    id: '2911015',
    name: 'edicion de periodicos y revistas',
  },
  {
    id: '2912005',
    name: 'edicion de libros y similares',
  },
  {
    id: '2912013',
    name: 'edicion de libros',
  },
  {
    id: '2921014',
    name: 'encuadernacion',
  },
  {
    id: '2921022',
    name: 'fabricacion de formularios y formas continuas',
  },
  {
    id: '2921030',
    name: 'fabricacion de libretas cuadernos y hojas para enc',
  },
  {
    id: '2921048',
    name: 'fotocopiado',
  },
  {
    id: '2921056',
    name: 'imprenta ( tipografia )',
  },
  {
    id: '2921064',
    name: 'impresion mediante cilindros de caucho',
  },
  {
    id: '2921072',
    name: 'impresion mediante cilindros (rotograbado)',
  },
  {
    id: '2929018',
    name: 'fabricacion de calcomanias',
  },
  {
    id: '2929026',
    name: 'fabricacion de tipos para imprenta',
  },
  {
    id: '2929034',
    name: 'grabado e impresion en piedra, vidrio y otros mate',
  },
  {
    id: '2929042',
    name: 'grabado e impresion fotomecanica mediante agua fue',
  },
  {
    id: '2929050',
    name: 'grabado en metal (fabricacion de cliches y fotogra',
  },
  {
    id: '3011012',
    name: 'fabricacion de anil',
  },
  {
    id: '3011020',
    name: 'fabricacion de anilinas',
  },
  {
    id: '3011038',
    name: 'fabricacion de otras materias colorantes',
  },
  {
    id: '3012010',
    name: 'fabricacion de gas acetileno',
  },
  {
    id: '3013018',
    name: 'fabricacion de acidos industriales',
  },
  {
    id: '3013026',
    name: 'fabricacion de otras substancias quimicas basicas',
  },
  {
    id: '3013034',
    name: 'fabricacion de productos amoniacales',
  },
  {
    id: '3013042',
    name: 'fabricacion de sosa',
  },
  {
    id: '3021011',
    name: 'fabricacion de abonos y fertilizantes quimicos',
  },
  {
    id: '3022019',
    name: 'fabricacion de insecticidas y plaguicidas',
  },
  {
    id: '3031010',
    name: 'fabricacion de hule espuma',
  },
  {
    id: '3032018',
    name: 'fabricacion de fibras sinteticas',
  },
  {
    id: '3041019',
    name: 'fabricacion de pinturas, barnices y lacas',
  },
  {
    id: '3051018',
    name: 'fabricacion de algodon esterilizado, gasas y venda',
  },
  {
    id: '3051026',
    name: 'fabricacion de otros productos farmaceuticos y med',
  },
  {
    id: '3051034',
    name: 'fabricacion de oxigeno medicinal',
  },
  {
    id: '3061017',
    name: 'fabricacion de dentifrico',
  },
  {
    id: '3061025',
    name: 'fabricacion de jabon y detergente',
  },
  {
    id: '3062015',
    name: 'fabricacion de perfumes y cosmeticos',
  },
  {
    id: '3071016',
    name: 'fabricacion de esencias',
  },
  {
    id: '3072014',
    name: 'fabricacion y refinacion de sebo grasas y aceites',
  },
  {
    id: '3072022',
    name: 'fundicion de sebo',
  },
  {
    id: '3072030',
    name: 'hidrogenadora de productos diversos',
  },
  {
    id: '3091014',
    name: 'fabricacion de colas y pegamentos',
  },
  {
    id: '3091022',
    name: 'fabricacion de impermeabilizantes',
  },
  {
    id: '3092012',
    name: 'fabricacion de cera pulimentos y abrillantadores',
  },
  {
    id: '3092020',
    name: 'fabricacion de desinfectantes y desodorizantes',
  },
  {
    id: '3092038',
    name: 'fabricacion de grasas y cremas lustradoras',
  },
  {
    id: '3092046',
    name: 'fabricacion de lanolina',
  },
  {
    id: '3093010',
    name: 'fabricacion de aguarras',
  },
  {
    id: '3094018',
    name: 'fabricacion de cerillos y fosforos',
  },
  {
    id: '3095016',
    name: 'fabricacion de otros articulos de cera',
  },
  {
    id: '3095024',
    name: 'fabricacion de velas veladoras y cirios',
  },
  {
    id: '3096014',
    name: 'fabricacion de tintas para impresion',
  },
  {
    id: '3097012',
    name: 'fabricacion de dinamita',
  },
  {
    id: '3097020',
    name: 'fabricacion de mechas para minas',
  },
  {
    id: '3097038',
    name: 'fabricacion de otros explosivos',
  },
  {
    id: '3097046',
    name: 'fabricacion de polvora',
  },
  {
    id: '3097054',
    name: 'fabricacion de productos pirotecnicos',
  },
  {
    id: '3099018',
    name: 'fabricacion de articulos de baquelita',
  },
  {
    id: '3099026',
    name: 'fabricacion de desincrustantes',
  },
  {
    id: '3099034',
    name: 'fabricacion de hielo seco',
  },
  {
    id: '3099042',
    name: 'industrializacion de basura',
  },
  {
    id: '3111010',
    name: 'fabricacion de gasolina y otros productos derivado',
  },
  {
    id: '3112018',
    name: 'fabricacion de productos petroquimicos basicos',
  },
  {
    id: '3113016',
    name: 'fabricacion de aceites y lubricantes',
  },
  {
    id: '3121019',
    name: 'fabricacion de coque y otros derivados del carbon',
  },
  {
    id: '3122017',
    name: 'fabricacion de materiales asfalticos para paviment',
  },
  {
    id: '3211018',
    name: 'fabricacion de llantas y camaras para vehiculos',
  },
  {
    id: '3212016',
    name: 'regeneracion de hule',
  },
  {
    id: '3212024',
    name: 'vulcanizacion de llantas y camaras',
  },
  {
    id: '3219012',
    name: 'fabricacion de articulos con hule usado',
  },
  {
    id: '3219020',
    name: 'fabricacion de linoleo',
  },
  {
    id: '3219038',
    name: 'fabricacion de otros articulos de hule',
  },
  {
    id: '3221017',
    name: 'fabricacion de laminas perfiles tubos y otros mate',
  },
  {
    id: '3222015',
    name: 'fabricacion de articulos de polietileno',
  },
  {
    id: '3222023',
    name: 'fabricacion de celuloide y polietileno',
  },
  {
    id: '3223013',
    name: 'fabricacion de calzado de plastico',
  },
  {
    id: '3223021',
    name: 'fabricacion de otros juguetes de plastico',
  },
  {
    id: '3229011',
    name: 'fabricacion de articulos de celuloide',
  },
  {
    id: '3229029',
    name: 'fabricacion de balones globos y pelotas',
  },
  {
    id: '3229037',
    name: 'fabricacion de botones de plastico',
  },
  {
    id: '3229045',
    name: 'fabricacion de otros articulos de plastico',
  },
  {
    id: '3229053',
    name: 'fabricacion de otros materiales de plastico',
  },
  {
    id: '3229061',
    name: 'fabricacion de peines peinetas y cepillos para uso',
  },
  {
    id: '3229079',
    name: 'fabricacion de pieles artificiales',
  },
  {
    id: '3311016',
    name: 'fabricacion de vajillas y otros productos de alfar',
  },
  {
    id: '3319010',
    name: 'elaboracion de objetos artisticos de alfareria y c',
  },
  {
    id: '3319028',
    name: 'fabricacion de azulejos',
  },
  {
    id: '3319036',
    name: 'fabricacion de loza y porcelana',
  },
  {
    id: '3319044',
    name: 'fabricacion de muebles y articulos sanitarios',
  },
  {
    id: '3321015',
    name: 'fabricacion de vidrio',
  },
  {
    id: '3322013',
    name: 'fabricacion de cristales para automovil',
  },
  {
    id: '3322021',
    name: 'fabricacion de fibra de vidrio',
  },
  {
    id: '3323011',
    name: 'fabricacion de ampolletas',
  },
  {
    id: '3323029',
    name: 'fabricacion de botellas',
  },
  {
    id: '3323037',
    name: 'fabricacion de crisoles',
  },
  {
    id: '3323045',
    name: 'fabricacion de otros envases de vidrio',
  },
  {
    id: '3324019',
    name: 'fabricacion de emplomados',
  },
  {
    id: '3324027',
    name: 'fabricacion de espejos y lunas',
  },
  {
    id: '3329019',
    name: 'fabricacion de esferas de vidrio',
  },
  {
    id: '3329027',
    name: 'fabricacion de objetos artisticos de vidrio y cris',
  },
  {
    id: '3329035',
    name: 'fabricacion de otros objetos de cristal',
  },
  {
    id: '3331014',
    name: 'fabricacion de adobe',
  },
  {
    id: '3331022',
    name: 'fabricacion de ladrillos',
  },
  {
    id: '3331030',
    name: 'fabricacion de teja y tubo de arcilla',
  },
  {
    id: '3332012',
    name: 'fabricacion de materiales para muros',
  },
  {
    id: '3332020',
    name: 'fabricacion de productos refractarios',
  },
  {
    id: '3341013',
    name: 'fabricacion de cemento',
  },
  {
    id: '3341021',
    name: 'fabricacion de concreto cientifico',
  },
  {
    id: '3341039',
    name: 'fabricacion de concreto para construccion',
  },
  {
    id: '3342011',
    name: 'fabricacion de bloques de cemento',
  },
  {
    id: '3342029',
    name: 'fabricacion de yeso',
  },
  {
    id: '3343019',
    name: 'horno de cal',
  },
  {
    id: '3351012',
    name: 'fabricacion de tinacos de asbesto',
  },
  {
    id: '3352010',
    name: 'fabricacion de abrasivos',
  },
  {
    id: '3352028',
    name: 'fabricacion de esmeriles',
  },
  {
    id: '3352036',
    name: 'fabricacion de lija',
  },
  {
    id: '3353018',
    name: 'taller de marmoleria',
  },
  {
    id: '3354016',
    name: 'fabricacion de mosaicos terrazos y granito',
  },
  {
    id: '3354024',
    name: 'fabricacion de postes y durmientes de concreto',
  },
  {
    id: '3354032',
    name: 'fabricacion de tubos de concreto',
  },
  {
    id: '3354909',
    name: 'usuarios menores de industria de productos de mine',
  },
  {
    id: '3354917',
    name: 'cartera de productos minerales no metalicos de est',
  },
  {
    id: '3411014',
    name: 'fabricacion de fierro esponja',
  },
  {
    id: '3411022',
    name: 'fundicion de fierro y acero',
  },
  {
    id: '3411030',
    name: 'planta metalurgica',
  },
  {
    id: '3411907',
    name: 'usuarios menores de industria siderurgica y de pro',
  },
  {
    id: '3411915',
    name: 'cartera siderurgica y de productos metalicos de es',
  },
  {
    id: '3412012',
    name: 'fabricacion de laminas de hierro y acero',
  },
  {
    id: '3412020',
    name: 'fabricacion de otros articulos de lamina',
  },
  {
    id: '3413010',
    name: 'fabricacion de tubos de hierro y acero',
  },
  {
    id: '3421013',
    name: 'fundicion refinacion laminacion extrusion y estira',
  },
  {
    id: '3422011',
    name: 'fundicion laminacion extrusion y estiraje de alumi',
  },
  {
    id: '3423019',
    name: 'fabricacion de tubos de estaðo',
  },
  {
    id: '3423027',
    name: 'fabricacion de equipos para soldar y soldaduras',
  },
  {
    id: '3429017',
    name: 'fundicion de metales no ferruginosos',
  },
  {
    id: '3511012',
    name: 'fabricacion de cubiertos y cuchilleria',
  },
  {
    id: '3512010',
    name: 'fabricacion de utensilios agricolas y herramientas',
  },
  {
    id: '3513018',
    name: 'fabricacion de remaches',
  },
  {
    id: '3513026',
    name: 'fabricacion de tornillos tuercas y pijas',
  },
  {
    id: '3514016',
    name: 'fabricacion de clavos cadenas grapas y tachuelas',
  },
  {
    id: '3515014',
    name: 'fabricacion de candados',
  },
  {
    id: '3515022',
    name: 'fabricacion de cerraduras',
  },
  {
    id: '3516012',
    name: 'fabricacion de cortinas de metal',
  },
  {
    id: '3516020',
    name: 'fabricacion de jaulas de metal',
  },
  {
    id: '3516038',
    name: 'fabricacion de otros articulos de aluminio',
  },
  {
    id: '3516046',
    name: 'fabricacion de perfiles puertas y ventanas de meta',
  },
  {
    id: '3516054',
    name: 'taller de herreria',
  },
  {
    id: '3521011',
    name: 'fabricacion de butacas de metal',
  },
  {
    id: '3521029',
    name: 'fabricacion de estufas',
  },
  {
    id: '3521037',
    name: 'fabricacion de otros muebles de metal',
  },
  {
    id: '3531010',
    name: 'fabricacion de estructuras de metal',
  },
  {
    id: '3531028',
    name: 'fabricacion de tanques para envasado de gases o li',
  },
  {
    id: '3532018',
    name: 'fabricacion de calderas tanques y tinacos de metal',
  },
  {
    id: '3532026',
    name: 'fabricacion de calentadores para baðo',
  },
  {
    id: '3591014',
    name: 'fabricacion de envases de lamina',
  },
  {
    id: '3591022',
    name: 'taller de hojalateria',
  },
  {
    id: '3592012',
    name: 'fabricacion de corcholatas',
  },
  {
    id: '3593010',
    name: 'fabricacion de alambrados y telas de metal',
  },
  {
    id: '3593028',
    name: 'fabricacion de alambre',
  },
  {
    id: '3593036',
    name: 'fabricacion de otros articulos de alambre',
  },
  {
    id: '3594018',
    name: 'fabricacion de articulos de peltre',
  },
  {
    id: '3595016',
    name: 'galvanizacion de lamina',
  },
  {
    id: '3595024',
    name: 'galvanizacion de tuberia',
  },
  {
    id: '3595032',
    name: 'taller de cobrizado',
  },
  {
    id: '3595040',
    name: 'taller de cromado y niquelado',
  },
  {
    id: '3595058',
    name: 'taller de esmaltado',
  },
  {
    id: '3596014',
    name: 'fabricacion de piezas metalicas por fundicion y mo',
  },
  {
    id: '3596022',
    name: 'fabricacion de tubos de cobre, plomo y aluminio',
  },
  {
    id: '3599018',
    name: 'fabricacion de alfileres agujas y broches',
  },
  {
    id: '3599026',
    name: 'fabricacion de cajas fuertes',
  },
  {
    id: '3599034',
    name: 'fabricacion de cierres automaticos de metal',
  },
  {
    id: '3599042',
    name: 'fabricacion de espuelas frenos y arneses de metal',
  },
  {
    id: '3599050',
    name: 'fabricacion de hebillas de metal',
  },
  {
    id: '3599068',
    name: 'fabricacion de hojas para rasurar',
  },
  {
    id: '3599076',
    name: 'fabricacion de ojillos de metal',
  },
  {
    id: '3599084',
    name: 'fabricacion de otros articulos de laton',
  },
  {
    id: '3599092',
    name: 'fabricacion de otros articulos de metal no clasifi',
  },
  {
    id: '3599109',
    name: 'fabricacion de pasadores y horquillas',
  },
  {
    id: '3599117',
    name: 'fabricacion de planchas industriales',
  },
  {
    id: '3599125',
    name: 'fabricacion de tapones de metal',
  },
  {
    id: '3599133',
    name: 'fabricacion de troqueles',
  },
  {
    id: '3611010',
    name: 'fabricacion ensamble y reparacion de vehiculos de',
  },
  {
    id: '3611028',
    name: 'fabricacion de maquinaria e implementos agricolas',
  },
  {
    id: '3621019',
    name: 'fabricacion de herramientas de metal',
  },
  {
    id: '3621027',
    name: 'fabricacion de refacciones y maquinaria industrial',
  },
  {
    id: '3631018',
    name: 'fabricacion ensamble y reparacion de maquinaria y',
  },
  {
    id: '3632016',
    name: 'fabricacion ensamble y reparacion de maquinaria eq',
  },
  {
    id: '3639012',
    name: 'fabricacion de molinos para granos',
  },
  {
    id: '3639905',
    name: 'usuarios menores de fabricacion de maquinaria y ar',
  },
  {
    id: '3639913',
    name: 'cartera de fabricacion de maquinaria y articulos e',
  },
  {
    id: '3641017',
    name: 'fabricacion de maquinas calculadoras registradoras',
  },
  {
    id: '3641025',
    name: 'fab. de eq. de proces. electr. de datos',
  },
  {
    id: '3691012',
    name: 'fabricacion de maquinas de coser',
  },
  {
    id: '3692010',
    name: 'fabricacion ensamble y reparacion de gruas montaca',
  },
  {
    id: '3693018',
    name: 'fabricacion ensamble y reparacion de motores no el',
  },
  {
    id: '3694016',
    name: 'fabricacion de extinguidores',
  },
  {
    id: '3694024',
    name: 'fabricacion de bombas para agua',
  },
  {
    id: '3695014',
    name: 'fabricacion de valvulas de metal',
  },
  {
    id: '3696012',
    name: 'fabricacion de filtros de metal',
  },
  {
    id: '3697010',
    name: 'fabricacion e instalacion de equipos y aparatos de',
  },
  {
    id: '3699016',
    name: 'fabricacion de engranes de metal',
  },
  {
    id: '3699024',
    name: 'taller mecanico de fabricacion y reparacion de par',
  },
  {
    id: '3699032',
    name: 'fabricacion de accesorios para la industria textil',
  },
  {
    id: '3711018',
    name: 'fabricacion ensamble y reparacion de transformador',
  },
  {
    id: '3721017',
    name: 'fabricacion de radios tocadiscos grabadoras y tele',
  },
  {
    id: '3722015',
    name: 'fabricacion de discos y cintas para grabaciones',
  },
  {
    id: '3722023',
    name: 'grabacion de discos y cintas',
  },
  {
    id: '3723013',
    name: 'fabricacion de aparatos de intercomunicacion',
  },
  {
    id: '3729011',
    name: 'fabricacion de partes dispositivos y accesorios pa',
  },
  {
    id: '3731016',
    name: 'fabricacion de otros aparatos electricos para el h',
  },
  {
    id: '3731024',
    name: 'fabricacion de refrigeradores y equipos de calefac',
  },
  {
    id: '3791010',
    name: 'fabricacion de acumuladores',
  },
  {
    id: '3791028',
    name: 'fabricacion de pilas secas',
  },
  {
    id: '3792018',
    name: 'fabricacion de focos',
  },
  {
    id: '3793016',
    name: 'fabricacion de cables de metal',
  },
  {
    id: '3793024',
    name: 'fabricacion de material para instalaciones electri',
  },
  {
    id: '3793032',
    name: 'fabricacion de refacciones para aparatos electrico',
  },
  {
    id: '3799014',
    name: 'fabricacion de anuncios luminosos',
  },
  {
    id: '3799022',
    name: 'fabricacion de candiles y arbotantes',
  },
  {
    id: '3799030',
    name: 'fabricacion de lamparas electricas',
  },
  {
    id: '3811016',
    name: 'fabricacion y ensamble de automoviles y camiones',
  },
  {
    id: '3812014',
    name: 'fabricacion de carrocerias de metal',
  },
  {
    id: '3812022',
    name: 'fabricacion de furgones y vagones',
  },
  {
    id: '3812030',
    name: 'fabricacion y ensamble de otros vehiculos',
  },
  {
    id: '3813012',
    name: 'fabricacion de pistones bielas anillos cigueðales',
  },
  {
    id: '3814010',
    name: 'fabricacion de partes para el sistema de transmisi',
  },
  {
    id: '3815018',
    name: 'fabricacion de muelles y resortes para vehiculos',
  },
  {
    id: '3816016',
    name: 'fabricacion de partes para el sistema de frenos de',
  },
  {
    id: '3817014',
    name: 'fabricacion de bujias',
  },
  {
    id: '3819010',
    name: 'fabricacion de refacciones y accesorios automotric',
  },
  {
    id: '3821015',
    name: 'fabricacion ensamble y reparacion de carros de fer',
  },
  {
    id: '3831014',
    name: 'fabricacion y reparacion de buques y barcos',
  },
  {
    id: '3831022',
    name: 'fabricacion y reparacion de otros vehiculos acuati',
  },
  {
    id: '3832012',
    name: 'fabricacion ensamble y reparacion de aeronaves',
  },
  {
    id: '3891018',
    name: 'fabricacion y ensamble de motocicletas bicicletas',
  },
  {
    id: '3892016',
    name: 'fabricacion de partes refacciones y accesorios par',
  },
  {
    id: '3899012',
    name: 'fabricacion de carreolas y andaderas',
  },
  {
    id: '3911014',
    name: 'fabricacion y reparacion de articulos de optica',
  },
  {
    id: '3912012',
    name: 'fabricacion de camaras fotograficas de cine y proy',
  },
  {
    id: '3912020',
    name: 'fabricacion de material fotografico',
  },
  {
    id: '3921013',
    name: 'fabricacion de relojes',
  },
  {
    id: '3931012',
    name: 'fabricacion de medallas insignias y placas',
  },
  {
    id: '3932010',
    name: 'fabricacion de articulos de joyeria',
  },
  {
    id: '3932028',
    name: 'orfebreria',
  },
  {
    id: '3932036',
    name: 'tallado de piedras preciosas',
  },
  {
    id: '3933018',
    name: 'fabricacion de articulos de quincalleria y bisuter',
  },
  {
    id: '3941011',
    name: 'fabricacion de cuerdas para guitarra',
  },
  {
    id: '3941029',
    name: 'fabricacion y reparacion de instrumentos musicales',
  },
  {
    id: '3951010',
    name: 'fabricacion de aparatos para gimnasia',
  },
  {
    id: '3951028',
    name: 'fabricacion de mesas de billar',
  },
  {
    id: '3951036',
    name: 'fabricacion de otros articulos para deportes',
  },
  {
    id: '3961019',
    name: 'fabricacion de otros instrumentos de medicion',
  },
  {
    id: '3961027',
    name: 'fabricacion y reparacion de basculas y balanzas',
  },
  {
    id: '3961035',
    name: 'fabricacion y reparacion de intrumentos de fisica',
  },
  {
    id: '3961043',
    name: 'fabricacion y reparacion de instrumentos de ingeni',
  },
  {
    id: '3961051',
    name: 'fabricacion de aparatos ozonizadores',
  },
  {
    id: '3961069',
    name: 'fabricacion de taximetros',
  },
  {
    id: '3962017',
    name: 'fabricacion y reparacion de aparatos ortopedicos',
  },
  {
    id: '3962025',
    name: 'fabricacion y reparacion de instrumentos de cirugi',
  },
  {
    id: '3991016',
    name: 'fabricacion de juguetes excepto los de hule y de p',
  },
  {
    id: '3992014',
    name: 'fabricacion de articulos para escritorio',
  },
  {
    id: '3992022',
    name: 'fabricacion de canuteros',
  },
  {
    id: '3992030',
    name: 'fabricacion de cintas para maquinas calculadoras r',
  },
  {
    id: '3992048',
    name: 'fabricacion de lapices',
  },
  {
    id: '3992056',
    name: 'fabricacion de pizarrones',
  },
  {
    id: '3993012',
    name: 'fabricacion de sellos de goma',
  },
  {
    id: '3993020',
    name: 'fabricacion de sellos para carros de ferrocarril',
  },
  {
    id: '3994010',
    name: 'fabricacion de articulos para dentista',
  },
  {
    id: '3995018',
    name: 'fabricacion de cercos para calzado',
  },
  {
    id: '3995026',
    name: 'fabricacion de hormas para calzado y tacones',
  },
  {
    id: '3996016',
    name: 'fabricacion de escobas',
  },
  {
    id: '3997014',
    name: 'fabricacion de armas',
  },
  {
    id: '3997022',
    name: 'fabricacion de cartuchos',
  },
  {
    id: '3997030',
    name: 'fabricacion de casquillos',
  },
  {
    id: '3997048',
    name: 'fabricacion de municiones para caza',
  },
  {
    id: '3999010',
    name: 'fabricacion de abanicos',
  },
  {
    id: '3999028',
    name: 'fabricacion de articulos de carey',
  },
  {
    id: '3999036',
    name: 'fabricacion de articulos de concha',
  },
  {
    id: '3999044',
    name: 'fabricacion de bastones paraguas y sombrillas',
  },
  {
    id: '3999052',
    name: 'fabricacion de flores artificiales',
  },
  {
    id: '3999060',
    name: 'fabricacion de otros articulos de pluma de ave',
  },
  {
    id: '3999078',
    name: 'fabricacion de rotulos',
  },
  {
    id: '3999086',
    name: 'fabricacion de talco industrial',
  },
  {
    id: '3999094',
    name: 'fabricacion de tragaluces y marquesinas',
  },
  {
    id: '3999101',
    name: 'fabricacion de productos manufacturados no clasifi',
  },
  {
    id: '3999119',
    name: 'ajuste programa venta de cartera fobaproa',
  },
  {
    id: '3999127',
    name: 'ajuste estadistico',
  },
  {
    id: '3999903',
    name: 'usuarios menores de industria manufacturera',
  },
  {
    id: '3999911',
    name: 'cartera de industria manufacturera de estados anal',
  },
  {
    id: '4111019',
    name: 'construccion de casas y techos desarmables',
  },
  {
    id: '4111027',
    name: 'construccion de inmuebles',
  },
  {
    id: '4111035',
    name: 'prestamos para la construccion de vivienda, tanto',
  },
  {
    id: '4111043',
    name: 'prestamos para la construccion de vivienda para ac',
  },
  {
    id: '4111051',
    name: 'desarrolladores de vivienda',
  },
  {
    id: '4111910',
    name: 'cartera de vivienda de interes social de estados a',
  },
  {
    id: '4111928',
    name: 'construccion de vivienda tipo medio',
  },
  {
    id: '4111936',
    name: 'usuarios menores de construccion de vivienda tipo',
  },
  {
    id: '4111944',
    name: 'construccion de vivienda residencial',
  },
  {
    id: '4111952',
    name: 'usuarios menores de construccion de vivienda resid',
  },
  {
    id: '4112017',
    name: 'construccion de edificios para oficinas escuelas h',
  },
  {
    id: '4113015',
    name: 'construccion de edificios industriales y para fine',
  },
  {
    id: '4121018',
    name: 'construccion de vias de comunicacion',
  },
  {
    id: '4121026',
    name: 'pavimentacion',
  },
  {
    id: '4121034',
    name: 'urbanizacion',
  },
  {
    id: '4122016',
    name: 'construccion de puertos',
  },
  {
    id: '4123014',
    name: 'construccion e instalacion de lineas telefonicas t',
  },
  {
    id: '4129012',
    name: 'construccion de pistas de aterrizaje ductos y otra',
  },
  {
    id: '4191011',
    name: 'construccion de presas',
  },
  {
    id: '4192019',
    name: 'perforacion de norias y pozos para agua',
  },
  {
    id: '4193017',
    name: 'construccion e instalacion de plantas generadoras',
  },
  {
    id: '4194015',
    name: 'perforacion de pozos petroleros y de gas',
  },
  {
    id: '4199015',
    name: 'construccion de estadios monumentos y otras obras',
  },
  {
    id: '4199908',
    name: 'usuarios menores de construccion',
  },
  {
    id: '4199916',
    name: 'cartera de construccion de estados analiticos',
  },
  {
    id: '4211017',
    name: 'premezclado y colado de concreto',
  },
  {
    id: '4212015',
    name: 'demolicion de inmuebles',
  },
  {
    id: '4219011',
    name: 'impermeabilizacion de inmuebles',
  },
  {
    id: '4221016',
    name: 'taller de plomeria',
  },
  {
    id: '4222014',
    name: 'instalaciones del sistema electrico incluye sistem',
  },
  {
    id: '4229010',
    name: 'instalaciones de sistemas de aire acondicionado y',
  },
  {
    id: '4291019',
    name: 'servicios de pintado y tapizado de inmuebles',
  },
  {
    id: '4292017',
    name: 'labrado y colocacion de piedra',
  },
  {
    id: '4293015',
    name: 'colocacion de duelas parquet lambrines y otros tra',
  },
  {
    id: '4299013',
    name: 'otros servicios de acabado prestados por subcontra',
  },
  {
    id: '5011010',
    name: 'generacion y suministro de energia electrica',
  },
  {
    id: '5011903',
    name: 'usuarios menores de energia electrica',
  },
  {
    id: '5011911',
    name: 'cartera de energia electrica de estados analiticos',
  },
  {
    id: '5012018',
    name: 'distribucion de energia electrica',
  },
  {
    id: '5111018',
    name: 'captacion, tratamiento, conduccion y distribucion',
  },
  {
    id: '6111017',
    name: 'compra venta de semillas y granos',
  },
  {
    id: '6112015',
    name: 'compra venta de frutas',
  },
  {
    id: '6112023',
    name: 'compra venta de legumbres y hortalizas',
  },
  {
    id: '6113013',
    name: 'compra venta de chile seco y especias',
  },
  {
    id: '6114011',
    name: 'compra venta de forrajes en estado natural',
  },
  {
    id: '6119011',
    name: 'compra venta de otros productos alimenticios agric',
  },
  {
    id: '6121016',
    name: 'compra venta de aves en pie',
  },
  {
    id: '6121024',
    name: 'compra venta de ganado mayor en pie',
  },
  {
    id: '6121032',
    name: 'compra venta de ganado menor en pie',
  },
  {
    id: '6122014',
    name: 'compra venta de carne de res y otras especies de g',
  },
  {
    id: '6123012',
    name: 'compra venta de visceras de ganado crudas y semicr',
  },
  {
    id: '6124010',
    name: 'compra venta de carne de aves',
  },
  {
    id: '6125018',
    name: 'compra venta de huevo',
  },
  {
    id: '6126016',
    name: 'compra venta de manteca',
  },
  {
    id: '6126024',
    name: 'compra venta de materias primas animales',
  },
  {
    id: '6126032',
    name: 'compra venta de pescados y mariscos',
  },
  {
    id: '6131015',
    name: 'salchichoneria',
  },
  {
    id: '6131023',
    name: 'tienda de abarrotes y miscelanea',
  },
  {
    id: '6132013',
    name: 'compra venta de dulces',
  },
  {
    id: '6132021',
    name: 'compra venta de especias y articulos alimenticos d',
  },
  {
    id: '6132039',
    name: 'compra venta de leche',
  },
  {
    id: '6132047',
    name: 'compra venta de otros productos lacteos',
  },
  {
    id: '6132055',
    name: 'compra venta de pan y pasteles',
  },
  {
    id: '6132063',
    name: 'compra venta de sal',
  },
  {
    id: '6132071',
    name: 'venta y distribucion de despensas familiares',
  },
  {
    id: '6132089',
    name: 'compra venta de azucar',
  },
  {
    id: '6133011',
    name: 'compraventa de alimento para ganado',
  },
  {
    id: '6133029',
    name: 'compraventa de alimentos para aves y otros animale',
  },
  {
    id: '6134019',
    name: 'compra venta de refrescos aguas gaseosas y aguas p',
  },
  {
    id: '6135017',
    name: 'compra venta de cerveza',
  },
  {
    id: '6136015',
    name: 'compra venta de pulque',
  },
  {
    id: '6136023',
    name: 'compra venta de vinos y licores',
  },
  {
    id: '6139019',
    name: 'compra venta de tabaco',
  },
  {
    id: '6139027',
    name: 'compra venta de tabacos puros y cigarros',
  },
  {
    id: '6211015',
    name: 'compra venta de articulos de lenceria',
  },
  {
    id: '6211023',
    name: 'compra venta de ropa',
  },
  {
    id: '6212013',
    name: 'compra venta de calzado',
  },
  {
    id: '6213011',
    name: 'compra venta de sombreros',
  },
  {
    id: '6214019',
    name: 'compra venta de pieles finas con pelo',
  },
  {
    id: '6215017',
    name: 'compra venta de articulos de merceria y sederia',
  },
  {
    id: '6215025',
    name: 'compraventa de articulos de boneteria',
  },
  {
    id: '6216015',
    name: 'compra venta de casimires',
  },
  {
    id: '6216023',
    name: 'compra venta de otros productos textiles',
  },
  {
    id: '6216031',
    name: 'compra venta de telas',
  },
  {
    id: '6221014',
    name: 'compra venta de articulos para deporte',
  },
  {
    id: '6222012',
    name: 'compra venta de articulos de optica',
  },
  {
    id: '6223010',
    name: 'compra venta de juguetes',
  },
  {
    id: '6224018',
    name: 'compra venta de instrumentos y articulos musicales',
  },
  {
    id: '6225016',
    name: 'compra venta de articulos de plata',
  },
  {
    id: '6225024',
    name: 'compra venta de otras joyas',
  },
  {
    id: '6225032',
    name: 'compra venta de relojes',
  },
  {
    id: '6226014',
    name: 'compra venta de articulos de talabarteria',
  },
  {
    id: '6226022',
    name: 'compra venta de otros articulos de piel',
  },
  {
    id: '6226030',
    name: 'compra venta de sillas de montar',
  },
  {
    id: '6227012',
    name: 'compra venta de libros',
  },
  {
    id: '6228010',
    name: 'distribucion y compraventa de periodicos y revista',
  },
  {
    id: '6229018',
    name: 'compra venta de articulos fotograficos y cinematog',
  },
  {
    id: '6231013',
    name: 'compra venta de medicinas',
  },
  {
    id: '6231021',
    name: 'compra venta de perfumes',
  },
  {
    id: '6232011',
    name: 'distribucion de otros productos quimicos-farmaceut',
  },
  {
    id: '6233019',
    name: 'compra venta de papeleria y articulos de escritori',
  },
  {
    id: '6239017',
    name: 'compra venta de paraguas sombrillas ropa usada y o',
  },
  {
    id: '6311013',
    name: 'compra venta de refrigeradores lavadoras y estufas',
  },
  {
    id: '6311021',
    name: 'compra venta de televisores consolas radios modula',
  },
  {
    id: '6311039',
    name: 'compraventa de otros aparatos electricos y electro',
  },
  {
    id: '6312011',
    name: 'compra venta de muebles',
  },
  {
    id: '6313019',
    name: 'compra venta de maquinas de coser y tejedoras',
  },
  {
    id: '6314017',
    name: 'compra venta de refacciones y accesorios de maquin',
  },
  {
    id: '6321012',
    name: 'compra venta de articulos de cristal loza y porcel',
  },
  {
    id: '6322010',
    name: 'compra venta de articulos para decoracion',
  },
  {
    id: '6322028',
    name: 'compra venta de tapetes y alfombras',
  },
  {
    id: '6323018',
    name: 'compra venta de discos y cassettes',
  },
  {
    id: '6324016',
    name: 'compra venta de articulos religiosos',
  },
  {
    id: '6325014',
    name: 'compra venta de antiguedades',
  },
  {
    id: '6325022',
    name: 'compra venta de articulos de yute y henequen',
  },
  {
    id: '6325030',
    name: 'compra venta de articulos para regalo',
  },
  {
    id: '6325048',
    name: 'compra venta de articulos regionales curiosidades',
  },
  {
    id: '6326012',
    name: 'compra venta de flores y adornos florales artifici',
  },
  {
    id: '6326020',
    name: 'compra venta de flores y adornos florales naturale',
  },
  {
    id: '6329016',
    name: 'compra venta de otros articulos para el hogar',
  },
  {
    id: '6411011',
    name: 'tienda de autoservicio',
  },
  {
    id: '6412019',
    name: 'tiendas de departamentos especializados excepto de',
  },
  {
    id: '6419015',
    name: 'compra venta de otros aparatos electricos',
  },
  {
    id: '6419023',
    name: 'compra venta de refacciones para aparatos electric',
  },
  {
    id: '6511019',
    name: 'compra venta de fluidos y gases',
  },
  {
    id: '6512017',
    name: 'compra venta de gas para uso domestico o comercial',
  },
  {
    id: '6513015',
    name: 'compra venta de gasolina y diesel',
  },
  {
    id: '6514013',
    name: 'compra venta de petroleo combustible',
  },
  {
    id: '6515011',
    name: 'compraventa de lubricantes',
  },
  {
    id: '6519013',
    name: 'compra venta de carbon y otros combustibles',
  },
  {
    id: '6611017',
    name: 'compra venta de algodon',
  },
  {
    id: '6612015',
    name: 'compra venta de semillas para siembra',
  },
  {
    id: '6613013',
    name: 'compra venta de cueros y pieles sin curtir',
  },
  {
    id: '6619011',
    name: 'compra venta de henequen e ixtle',
  },
  {
    id: '6619029',
    name: 'compra venta de lana',
  },
  {
    id: '6619037',
    name: 'compra venta de otras materias primas vegetales',
  },
  {
    id: '6619045',
    name: 'compra venta de otras fibras comerciales',
  },
  {
    id: '6619053',
    name: 'compra venta de otras materias primas de origen an',
  },
  {
    id: '6621016',
    name: 'compra venta de cemento cal yeso y otros productos',
  },
  {
    id: '6622014',
    name: 'compra venta de fierro laminado y en lingotes',
  },
  {
    id: '6622022',
    name: 'compraventa de articulos de ferreteria',
  },
  {
    id: '6623012',
    name: 'compra venta de colorantes para la industria',
  },
  {
    id: '6623020',
    name: 'compra venta de pinturas barnices y brochas',
  },
  {
    id: '6624010',
    name: 'compra venta de madera',
  },
  {
    id: '6625018',
    name: 'compra venta de vidrios cristales y emplomados',
  },
  {
    id: '6626016',
    name: 'compra venta de anilinas',
  },
  {
    id: '6626024',
    name: 'compra venta de articulos de tlapaleria',
  },
  {
    id: '6626032',
    name: 'compra venta de costales y bolsas',
  },
  {
    id: '6627014',
    name: 'compra de articulos sanitarios',
  },
  {
    id: '6628012',
    name: 'compra venta de material para instalaciones electr',
  },
  {
    id: '6629010',
    name: 'compra venta de materiales para construccion',
  },
  {
    id: '6629028',
    name: 'compra venta de tapices',
  },
  {
    id: '6691019',
    name: 'compra venta de fertilizantes y plaguicidas',
  },
  {
    id: '6692017',
    name: 'compra venta de articulos de peleteria',
  },
  {
    id: '6693015',
    name: 'compra venta de desperdicios de papel',
  },
  {
    id: '6694013',
    name: 'compra venta de fierro usado',
  },
  {
    id: '6695011',
    name: 'compraventa de substancias quimicas para la indust',
  },
  {
    id: '6699013',
    name: 'compra venta de ceras y parafinas',
  },
  {
    id: '6699021',
    name: 'compra venta de desperdicios industriales',
  },
  {
    id: '6699039',
    name: 'compra venta de materias primas de origen mineral',
  },
  {
    id: '6699047',
    name: 'compraventa de otros metales y minerales laminados',
  },
  {
    id: '6711015',
    name: 'compra venta de maquinaria equipo e implementos pa',
  },
  {
    id: '6712013',
    name: 'compra venta de articulos para la explotacion de m',
  },
  {
    id: '6713011',
    name: 'compraventa maq, eq, implem y herram para trabajar',
  },
  {
    id: '6714019',
    name: 'compra venta de maquinaria nueva',
  },
  {
    id: '6714027',
    name: 'compra venta de maquinaria usada',
  },
  {
    id: '6714035',
    name: 'compra venta de equipo de trabajo y proteccion ind',
  },
  {
    id: '6719019',
    name: 'compra venta de refacciones para maquinaria',
  },
  {
    id: '6721014',
    name: 'compra venta de maquinas de escribir',
  },
  {
    id: '6721022',
    name: 'compra venta de muebles y articulos para oficina',
  },
  {
    id: '6721036',
    name: 'compra venta equipo de proce. electronico de datos',
  },
  {
    id: '6722012',
    name: 'compra venta de equipo y mobiliario para hoteles r',
  },
  {
    id: '6729018',
    name: 'compra venta de equipos de refrigeracion comercial',
  },
  {
    id: '6729026',
    name: 'compra venta de accesorios para muebles',
  },
  {
    id: '6729034',
    name: 'compra venta de equipo contra incendio',
  },
  {
    id: '6731013',
    name: 'compra venta de basculas balanzas y aparatos simil',
  },
  {
    id: '6732011',
    name: 'compra venta de articulos medicos',
  },
  {
    id: '6732029',
    name: 'compra venta de articulos y aparatos ortopedicos',
  },
  {
    id: '6732037',
    name: 'compra venta de muebles para consultorios y sanato',
  },
  {
    id: '6732045',
    name: 'compraventa de equipos quimicos y para laboratorio',
  },
  {
    id: '6739017',
    name: 'compra venta de aparatos cientificos y de precisio',
  },
  {
    id: '6811013',
    name: 'compra venta de automoviles y camiones nuevos',
  },
  {
    id: '6812011',
    name: 'compra venta de automoviles y camiones usados',
  },
  {
    id: '6813019',
    name: 'compra venta de bicicletas y sus accesorios',
  },
  {
    id: '6813027',
    name: 'compra venta de motocicletas y sus accesorios',
  },
  {
    id: '6814017',
    name: 'compra venta de llantas',
  },
  {
    id: '6815015',
    name: 'compraventa de refacciones y accesorios nuevos par',
  },
  {
    id: '6816013',
    name: 'compra venta de refacciones y accesorios usados pa',
  },
  {
    id: '6819017',
    name: 'compra venta de partes y refacciones para vehiculo',
  },
  {
    id: '6819025',
    name: 'compra venta de vehiculos acuaticos y sus refaccio',
  },
  {
    id: '6819033',
    name: 'compraventa de vehiculos aereos',
  },
  {
    id: '6911011',
    name: 'prestamos para la adquisision de vivienda para acr',
  },
  {
    id: '6911029',
    name: 'compra de casa habitacion',
  },
  {
    id: '6911037',
    name: 'prestamos para la adquisicion de vivienda tanto de',
  },
  {
    id: '6911045',
    name: 'compra venta de casas y otros inmuebles',
  },
  {
    id: '6911053',
    name: 'compra venta de terrenos',
  },
  {
    id: '6911061',
    name: 'actualizacion mensual del saldo operaciones de cre',
  },
  {
    id: '6911079',
    name: 'adquisicion de vivienda tipo medio',
  },
  {
    id: '6911087',
    name: 'usuarios menores de adquisicion de vivienda tipo m',
  },
  {
    id: '6911095',
    name: 'adquisicion de vivienda residencial',
  },
  {
    id: '6911102',
    name: 'usuarios menores de adquisicion de vivienda reside',
  },
  {
    id: '6991013',
    name: 'compra venta de armas de fuego',
  },
  {
    id: '6992011',
    name: 'agencias de rifas y sorteos (quinielas y loteria)',
  },
  {
    id: '6993019',
    name: 'compraventa de productos veterinarios y otros arti',
  },
  {
    id: '6999017',
    name: 'compra venta de diamantes',
  },
  {
    id: '6999025',
    name: 'compra venta de equipos y aparatos de aire acondic',
  },
  {
    id: '6999033',
    name: 'compraventa de jarcieria reatas canastas y articul',
  },
  {
    id: '6999041',
    name: 'compra venta de otros articulos de plastico',
  },
  {
    id: '6999059',
    name: 'usuarios menores por operaciones de reporto',
  },
  {
    id: '6999067',
    name: 'compraventa de otros productos de hule',
  },
  {
    id: '6999075',
    name: 'compra venta de velas y veladoras',
  },
  {
    id: '6999083',
    name: 'creditos personales al consumo',
  },
  {
    id: '6999091',
    name: 'prestamos al personal de la institucion',
  },
  {
    id: '6999108',
    name: 'compra venta de articulos no clasificados en otra',
  },
  {
    id: '6999116',
    name: 'usuarios de tarjeta de credito',
  },
  {
    id: '6999124',
    name: 'creditos para adquisicion de bienes de consumo dur',
  },
  {
    id: '6999900',
    name: 'usuarios menores de comercio',
  },
  {
    id: '6999991',
    name: 'cartera de comercio de estados analiticos',
  },
  {
    id: '6999992',
    name: 'centros cambiarios',
  },
  {
    id: '7111016',
    name: 'transporte en autobus urbano y suburbano de pasaje',
  },
  {
    id: '7112014',
    name: 'transporte en autobus foraneo de pasajeros',
  },
  {
    id: '7113012',
    name: 'transporte en automovil de ruleteo',
  },
  {
    id: '7114010',
    name: 'transporte en automoviles de sitio y turismo',
  },
  {
    id: '7115018',
    name: 'transporte en automovil de ruta fija',
  },
  {
    id: '7119010',
    name: 'autotransporte escolar turistico en autobus y otro',
  },
  {
    id: '7121015',
    name: 'autotransporte de materiales de construccion',
  },
  {
    id: '7122013',
    name: 'agencia de mudanzas',
  },
  {
    id: '7123011',
    name: 'autotransporte de carga de productos especificos',
  },
  {
    id: '7129019',
    name: 'transporte de carga foranea',
  },
  {
    id: '7129902',
    name: 'usuarios menores de transportes',
  },
  {
    id: '7129910',
    name: 'cartera de transportes de estados analiticos',
  },
  {
    id: '7131014',
    name: 'ferrocarriles',
  },
  {
    id: '7132012',
    name: 'tranvias y trolebuses',
  },
  {
    id: '7133010',
    name: 'transporte en ferrocarril urbano (metro)',
  },
  {
    id: '7191018',
    name: 'transporte por ductos',
  },
  {
    id: '7211014',
    name: 'agencia de vapores y buques',
  },
  {
    id: '7212012',
    name: 'transportes maritimos de cabotaje',
  },
  {
    id: '7291016',
    name: 'transporte fluvial y lacustre',
  },
  {
    id: '7299010',
    name: 'carga y estiba portuaria',
  },
  {
    id: '7311012',
    name: 'transportacion aerea de pasajeros',
  },
  {
    id: '7312010',
    name: 'servicios relacionados con el transporte en aerona',
  },
  {
    id: '7411010',
    name: 'administracion de caminos puentes y servicios auxi',
  },
  {
    id: '7412018',
    name: 'aeropuerto civil',
  },
  {
    id: '7413016',
    name: 'administracion de puertos maritimos lacustres y fl',
  },
  {
    id: '7414014',
    name: 'administracion de centrales camioneras y servicios',
  },
  {
    id: '7511018',
    name: 'servicios de almacenamiento y refrigeracion',
  },
  {
    id: '7512016',
    name: 'agencia de turismo',
  },
  {
    id: '7513014',
    name: 'agencia aduanal',
  },
  {
    id: '7514012',
    name: 'servicios de bascula y de grua para vehiculos',
  },
  {
    id: '7519012',
    name: 'agencia de ferrocarril',
  },
  {
    id: '7519020',
    name: 'alquiler de lanchas y veleros',
  },
  {
    id: '7519038',
    name: 'renta de vehiculos aereos',
  },
  {
    id: '7611016',
    name: 'empresa de telefonos',
  },
  {
    id: '7612014',
    name: 'empresa de telegrafos',
  },
  {
    id: '7613012',
    name: 'otros servs. de telecomunicaciones (excepto radio.',
  },
  {
    id: '7613905',
    name: 'usuarios menores de comunicaciones',
  },
  {
    id: '7613913',
    name: 'cartera de comunicaciones de estados analiticos',
  },
  {
    id: '7614010',
    name: 'servicios postales',
  },
  {
    id: '8113011',
    name: 'servicios de la banca nacional',
  },
  {
    id: '8113029',
    name: 'servicios de la banca nacional por operaciones de',
  },
  {
    id: '8113128',
    name: 'usuarios menores de la banca de desarrollo',
  },
  {
    id: '8114019',
    name: 'servicios de fondos y fideicomisos de fomento econ',
  },
  {
    id: '8123010',
    name: 'servicios de la banca privada y mixta multiple',
  },
  {
    id: '8123028',
    name: 'servicios de la banca privada especializada',
  },
  {
    id: '8123036',
    name: 'servicios de la banca privada y mixta multiple por',
  },
  {
    id: '8123044',
    name: 'servicios de la banca privada especializada por op',
  },
  {
    id: '8123052',
    name: 'sociedades de ahorro y prestamo',
  },
  {
    id: '8123060',
    name: 'sociedades de ahorro y credito popular',
  },
  {
    id: '8123078',
    name: 'sociedades financieras de objeto limitado',
  },
  {
    id: '8123086',
    name: 'sociedades financieras de objeto míltiple regulada',
  },
  {
    id: '8123094',
    name: 'sociedades financieras de objeto míltiple no regul',
  },
  {
    id: '8123143',
    name: 'usuarios menores de la banca comercial',
  },
  {
    id: '8123903',
    name: 'usuarios menores de servicios bancarios',
  },
  {
    id: '8123911',
    name: 'cartera de servicios bancarios de estados analitic',
  },
  {
    id: '8131013',
    name: 'almacenes de deposito nacionales',
  },
  {
    id: '8131021',
    name: 'almacenes de deposito privados',
  },
  {
    id: '8132011',
    name: 'uniones de credito nacionales',
  },
  {
    id: '8132029',
    name: 'uniones de credito privadas',
  },
  {
    id: '8133019',
    name: 'compañias de fianzas nacionales',
  },
  {
    id: '8133027',
    name: 'compaðias de fianzas privadas',
  },
  {
    id: '8133035',
    name: 'aj. int. financieros no bancarios udis',
  },
  {
    id: '8141012',
    name: 'bolsa de valores',
  },
  {
    id: '8142010',
    name: 'sociedades de inversion',
  },
  {
    id: '8151011',
    name: 'compaðias de seguros nacionales',
  },
  {
    id: '8151029',
    name: 'compaðias de seguros privadas',
  },
  {
    id: '8211013',
    name: 'inversionista',
  },
  {
    id: '8211021',
    name: 'agente de bolsa',
  },
  {
    id: '8211039',
    name: 'bolsa de valores',
  },
  {
    id: '8211047',
    name: 'casas de bolsa privadas',
  },
  {
    id: '8219017',
    name: 'agente de seguros',
  },
  {
    id: '8219025',
    name: 'casa de cambio',
  },
  {
    id: '8219033',
    name: 'corresponsal bancario',
  },
  {
    id: '8219041',
    name: 'caja de ahorros',
  },
  {
    id: '8219059',
    name: 'montepio',
  },
  {
    id: '8219067',
    name: 'prestamista',
  },
  {
    id: '8219075',
    name: 'factoring',
  },
  {
    id: '8219083',
    name: 'empresas controladoras financieras',
  },
  {
    id: '8219091',
    name: 'usuarios menores de otros intermediarios financier',
  },
  {
    id: '8219108',
    name: 'usuarios menores de otros intermediarios financier',
  },
  {
    id: '8219114',
    name: 'administradoras de tarjetas de credito',
  },
  {
    id: '8219122',
    name: 'empresas de autofinanciamiento automotriz',
  },
  {
    id: '8219130',
    name: 'empresas de autofinanciamiento residencial',
  },
  {
    id: '8220014',
    name: 'fobaproa-ipab',
  },
  {
    id: '8311011',
    name: 'alquiler de terrenos, locales y edificios no resid',
  },
  {
    id: '8312019',
    name: 'arrendamiento de inmuebles residenciales',
  },
  {
    id: '8313017',
    name: 'servicio de corredores de bienes raices',
  },
  {
    id: '8314015',
    name: 'administracion de inmuebles',
  },
  {
    id: '8411019',
    name: 'servicios de notarias publicas',
  },
  {
    id: '8412017',
    name: 'servicios de bufetes juridicos',
  },
  {
    id: '8413015',
    name: 'servicios de contaduria y auditoria incluso tened',
  },
  {
    id: '8414013',
    name: 'servicios de asesoria y estudios tecnicos de arqui',
  },
  {
    id: '8415011',
    name: 'despacho de otros profesionistas',
  },
  {
    id: '8419013',
    name: 'servicio de investigacion de mercado, solvencia fi',
  },
  {
    id: '8421018',
    name: 'servicios de analisis de sistemas y procesamiento',
  },
  {
    id: '8422016',
    name: 'agencia de publicidad',
  },
  {
    id: '8423014',
    name: 'agencia noticiosa',
  },
  {
    id: '8424012',
    name: 'servicios administrativos de tramite y cobranza in',
  },
  {
    id: '8425010',
    name: 'servicios de copias fotostaticas xerograficas y si',
  },
  {
    id: '8426018',
    name: 'comisionista',
  },
  {
    id: '8426026',
    name: 'representacion de artistas',
  },
  {
    id: '8426034',
    name: 'representante casas extranjeras',
  },
  {
    id: '8426042',
    name: 'representante casas nacionales',
  },
  {
    id: '8427016',
    name: 'servicios de instalacion y mantenimiento de maquin',
  },
  {
    id: '8428014',
    name: 'servicios de agencias de colocacion y seleccion de',
  },
  {
    id: '8429012',
    name: 'prestacion de otros servicios tecnicos',
  },
  {
    id: '8429020',
    name: 'empresas controladoras no financieras',
  },
  {
    id: '8429038',
    name: 'empresas de seguridad privada',
  },
  {
    id: '8429046',
    name: 'empresas transportadoras de valores',
  },
  {
    id: '8511017',
    name: 'arrendamiento de maquinaria',
  },
  {
    id: '8511025',
    name: 'arrendadoras financieras nacionales',
  },
  {
    id: '8511033',
    name: 'arrendadoras financieras privadas',
  },
  {
    id: '8512015',
    name: 'arrendamiento de equipo para procesamiento electro',
  },
  {
    id: '8519011',
    name: 'alquiler de mobiliario y equipo para comercios, se',
  },
  {
    id: '8521016',
    name: 'salon de fiestas',
  },
  {
    id: '8522014',
    name: 'alquiler de sillas y vajillas para banquetes',
  },
  {
    id: '8523012',
    name: 'alquiler de equipos electronicos',
  },
  {
    id: '8524010',
    name: 'alquiler o renta de automoviles sin chofer',
  },
  {
    id: '8529010',
    name: 'alquiler de ropa',
  },
  {
    id: '8611015',
    name: 'hotel',
  },
  {
    id: '8612013',
    name: 'servicios de alojamiento en moteles',
  },
  {
    id: '8613011',
    name: 'campos de turismo',
  },
  {
    id: '8619019',
    name: 'casa de huespedes',
  },
  {
    id: '8711013',
    name: 'cafeteria',
  },
  {
    id: '8711021',
    name: 'restaurante',
  },
  {
    id: '8712011',
    name: 'servicios de alimentos en loncherias taquerias y t',
  },
  {
    id: '8713019',
    name: 'servicios en ostionerias y preparacion de otros ma',
  },
  {
    id: '8714017',
    name: 'neveria y refresqueria',
  },
  {
    id: '8719017',
    name: 'servicios en merenderos cenadurias y preparacion d',
  },
  {
    id: '8721012',
    name: 'bares y cantinas',
  },
  {
    id: '8722010',
    name: 'cerveceria',
  },
  {
    id: '8723018',
    name: 'servicios en pulquerias',
  },
  {
    id: '8723026',
    name: 'usuarios menores de turismo',
  },
  {
    id: '8811011',
    name: 'servicios en balnearios y albercas',
  },
  {
    id: '8812019',
    name: 'salon de billar',
  },
  {
    id: '8812027',
    name: 'salon de boliche',
  },
  {
    id: '8813017',
    name: 'club social',
  },
  {
    id: '8814015',
    name: 'centro deportivo',
  },
  {
    id: '8819015',
    name: 'servicios y explotacion de playas y parques de des',
  },
  {
    id: '8821010',
    name: 'estudios cinematograficos',
  },
  {
    id: '8821028',
    name: 'grabacion de sonido en peliculas',
  },
  {
    id: '8821036',
    name: 'laboratorios cinematograficos',
  },
  {
    id: '8821044',
    name: 'produccion de peliculas',
  },
  {
    id: '8822018',
    name: 'distribucion y alquiler de peliculas',
  },
  {
    id: '8823016',
    name: 'sala de cine',
  },
  {
    id: '8824014',
    name: 'radiodifusora',
  },
  {
    id: '8825012',
    name: 'teledifusora',
  },
  {
    id: '8826010',
    name: 'teatro',
  },
  {
    id: '8827018',
    name: 'actividades de clubes deportivos profesionales',
  },
  {
    id: '8829014',
    name: 'estadios y arenas',
  },
  {
    id: '8829022',
    name: 'hipodromo',
  },
  {
    id: '8829030',
    name: 'plaza de toros',
  },
  {
    id: '8829048',
    name: 'promocion de espectaculos deportivos',
  },
  {
    id: '8829056',
    name: 'promocion de espectaculos taurinos',
  },
  {
    id: '8831019',
    name: 'centro nocturno',
  },
  {
    id: '8832017',
    name: 'galerias de artes graficas y museos',
  },
  {
    id: '8833015',
    name: 'federaciones y asociaciones deportivas y otras con',
  },
  {
    id: '8839013',
    name: 'juegos de feria',
  },
  {
    id: '8839906',
    name: 'usuarios menores de servicios de esparcimiento',
  },
  {
    id: '8839914',
    name: 'cartera de servicios de esparcimiento de estados a',
  },
  {
    id: '8911019',
    name: 'taller de reparacion general de automoviles y cami',
  },
  {
    id: '8912017',
    name: 'servicios de reparacion de motocicletas y biciclet',
  },
  {
    id: '8913015',
    name: 'servicios de reparacion especializado en partes de',
  },
  {
    id: '8914013',
    name: 'servicios de reparacion de carrocerias pintura tap',
  },
  {
    id: '8915011',
    name: 'servicios de lavado y lubricacion de automoviles',
  },
  {
    id: '8916019',
    name: 'estacionamiento privado para vehiculos',
  },
  {
    id: '8916027',
    name: 'estacionamiento publico para vehiculos',
  },
  {
    id: '8919013',
    name: 'servicio de reparacion de vehiculos de traccion an',
  },
  {
    id: '8921018',
    name: 'taller de reparacion de calzado',
  },
  {
    id: '8922016',
    name: 'taller de reparacion de aparatos electricos y elec',
  },
  {
    id: '8922024',
    name: 'taller de reparacion de articulos electricos y ele',
  },
  {
    id: '8923014',
    name: 'servicios de reparacion de relojes y joyas',
  },
  {
    id: '8924012',
    name: 'taller de tapiceria',
  },
  {
    id: '8929012',
    name: 'taller de reparacion de paraguas y sombrillas',
  },
  {
    id: '8929020',
    name: 'taller de reparacion de ropa y medias',
  },
  {
    id: '8929038',
    name: 'taller de reparacion de sombreros',
  },
  {
    id: '8931017',
    name: 'baños',
  },
  {
    id: '8932015',
    name: 'barberia y peluqueria',
  },
  {
    id: '8933013',
    name: 'salon de belleza',
  },
  {
    id: '8934011',
    name: 'lavanderia',
  },
  {
    id: '8934029',
    name: 'tintoreria y planchaduria',
  },
  {
    id: '8935019',
    name: 'empresa de servicio de limpieza',
  },
  {
    id: '8936017',
    name: 'servicios de fumigacion, desinfeccion y control de',
  },
  {
    id: '8939011',
    name: 'servicios de bolerias, masajistas, sanitarios publ',
  },
  {
    id: '8941016',
    name: 'estudio fotografico',
  },
  {
    id: '8942014',
    name: 'decoracion en general',
  },
  {
    id: '8943012',
    name: 'agencia de inhumaciones',
  },
  {
    id: '8944010',
    name: 'panteon',
  },
  {
    id: '8944028',
    name: 'usuarios menores de servicios profesionales y tecn',
  },
  {
    id: '8944098',
    name: 'empleado privado',
  },
  {
    id: '8949010',
    name: 'taxidermista',
  },
  {
    id: '8949888',
    name: 'usuarios menores de otras actividades',
  },
  {
    id: '8949896',
    name: 'cartera de otras actividades de estados analiticos',
  },
  {
    id: '8949903',
    name: 'usuarios menores de servicios',
  },
  {
    id: '8949911',
    name: 'cartera de servicios de estados analiticos',
  },
  {
    id: '8991011',
    name: 'quehaceres del hogar',
  },
  {
    id: '9112012',
    name: 'servicios de enseðanza preprimaria y primaria',
  },
  {
    id: '9113010',
    name: 'servicios de enseðanza secundaria',
  },
  {
    id: '9115016',
    name: 'establecimientos publicos de instruccion, educacio',
  },
  {
    id: '9119018',
    name: 'establecimientos privados de instruccion educacion',
  },
  {
    id: '9121013',
    name: 'servicios de enseðanza comercial y de idioma',
  },
  {
    id: '9122011',
    name: 'servicios de capacitacion tecnica de oficios y art',
  },
  {
    id: '9129017',
    name: 'servicios de enseðanza de musica danza y otras art',
  },
  {
    id: '9191016',
    name: 'servicios de investigacion cientifica',
  },
  {
    id: '9199010',
    name: 'servicios de bibliotecas museos jardines botanicos',
  },
  {
    id: '9211012',
    name: 'hospitales, sanatorios, clinicas y maternidades',
  },
  {
    id: '9212010',
    name: 'servicios medico general y especializado en consul',
  },
  {
    id: '9213018',
    name: 'servicios de consultorios y clinicas dentales',
  },
  {
    id: '9214016',
    name: 'laboratorios de analisis clinicos',
  },
  {
    id: '9215014',
    name: 'servicios de laboratorios de radiologia y radiosco',
  },
  {
    id: '9219016',
    name: 'servicios de fisioterapia, bancos de sangre y otro',
  },
  {
    id: '9221011',
    name: 'centro de beneficencia',
  },
  {
    id: '9231010',
    name: 'servicios veterinarios en clinicas y consultorios,',
  },
  {
    id: '9231028',
    name: 'usuarios menores de servicios medicos',
  },
  {
    id: '9311010',
    name: 'asociaciones y confederaciones',
  },
  {
    id: '9311028',
    name: 'camaras de comercio',
  },
  {
    id: '9311036',
    name: 'camaras industriales',
  },
  {
    id: '9311044',
    name: 'sociedades cooperativas',
  },
  {
    id: '9312018',
    name: 'organizaciones de abogados medicos ingenieros y ot',
  },
  {
    id: '9319014',
    name: 'organizaciones civicas',
  },
  {
    id: '9321019',
    name: 'organizaciones laborales y sindicales',
  },
  {
    id: '9322017',
    name: 'organizaciones politicas',
  },
  {
    id: '9331018',
    name: 'organizaciones religiosas',
  },
  {
    id: '9411018',
    name: 'gobierno federal',
  },
  {
    id: '9411026',
    name: 'gobierno estatal',
  },
  {
    id: '9411034',
    name: 'gobierno municipal',
  },
  {
    id: '9411042',
    name: 'departamento del distrito federal',
  },
  {
    id: '9411886',
    name: 'usuarios menores de gobierno federal',
  },
  {
    id: '9411894',
    name: 'cartera de gobierno federal de estados analiticos',
  },
  {
    id: '9411901',
    name: 'usuarios menores de gobierno estatal y municipal',
  },
  {
    id: '9411919',
    name: 'cartera de gobierno estatal y municipal de estados',
  },
  {
    id: '9411998',
    name: 'empleado publico',
  },
  {
    id: '9471012',
    name: 'prestacion de servicios publicos y sociales',
  },
  {
    id: '9911018',
    name: 'instituciones financieras del extranjero',
  },
  {
    id: '9912016',
    name: 'consulado',
  },
  {
    id: '9912024',
    name: 'gobierno extranjero',
  },
  {
    id: '9919012',
    name: 'servicios de oficinas y representaciones de otros',
  },
  {
    id: '9999999',
    name: 'no clasificada',
  },
];

export const TERMS = [
  {
    applyTo: PEOPLE_TYPE.INDIVIDUAL,
    label: '6 meses',
    value: 12,
  },
  {
    applyTo: PEOPLE_TYPE.INDIVIDUAL,
    label: '9 meses',
    value: 18,
  },
  {
    applyTo: PEOPLE_TYPE.INDIVIDUAL,
    label: '12 meses',
    value: 24,
  },
  {
    applyTo: PEOPLE_TYPE.INDIVIDUAL,
    label: '15 meses',
    value: 30,
  },
  {
    applyTo: PEOPLE_TYPE.INDIVIDUAL,
    label: '18 meses',
    value: 36,
  },
  {
    applyTo: PEOPLE_TYPE.INDIVIDUAL_BUSINESS,
    value: 6,
  },
  {
    applyTo: PEOPLE_TYPE.INDIVIDUAL_BUSINESS,
    value: 12,
  },
  {
    applyTo: PEOPLE_TYPE.INDIVIDUAL_BUSINESS,
    value: 18,
  },
  {
    applyTo: PEOPLE_TYPE.INDIVIDUAL_BUSINESS,
    value: 24,
  },
  {
    applyTo: PEOPLE_TYPE.INDIVIDUAL_BUSINESS,
    value: 36,
  },
  {
    applyTo: PEOPLE_TYPE.LEGAL,
    value: 6,
  },
  {
    applyTo: PEOPLE_TYPE.LEGAL,
    value: 12,
  },
  {
    applyTo: PEOPLE_TYPE.LEGAL,
    value: 18,
  },
  {
    applyTo: PEOPLE_TYPE.LEGAL,
    value: 24,
  },
  {
    applyTo: PEOPLE_TYPE.LEGAL,
    value: 36,
  },
];
