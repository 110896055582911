import Dynamicore, { SERVICES } from '../index';

async function getUserInfo() {
  const args = [...arguments];
  const { values: info } = await Dynamicore(SERVICES.USERS_GET_INFO).get();
  const key = `dcore:${info?.company}:client:`;
  const peopleId = String((info?.json_rol?.Statement ?? [])
    .find((item) => item.Resource.startsWith(key))?.Resource ?? '')
    .replace(key, '');
  const accountKey = `dcore:${info?.company}:account:`;
  const accountIds = (info?.json_rol?.Statement ?? [])
    .filter((item) => item.Resource.startsWith(accountKey))
    .map((item) => item.Resource.split(':').pop());
  const user = {
    id: info.user,
    data: args.length > 0 ? {} : undefined,
    entities: {
      accountIds: accountIds,
      companyId: info.company,
      groupId: info.group,
      peopleId: parseInt(peopleId),
    },
  };

  if (args.includes('company')) {
    user.data.company = await Dynamicore(SERVICES.COMPANY).get({
      id: user?.entities?.companyId,
    });
  }

  if (args.includes('people')) {
    user.data.people = await Dynamicore(SERVICES.PEOPLE).get({
      id: user?.entities?.peopleId,
    });

    user.data.peopleType = await Dynamicore(SERVICES.PEOPLE_TYPES).get({
      id: user?.data?.people?.client_type ?? 0,
    });

    user.entities.peopleTypeId = user?.data?.peopleType?.id;
  }

  if (args.includes('relationships')) {

    const { values: relationships = [] } = await Dynamicore(SERVICES.PEOPLE_ORGANIZATIONAL_OLD).get({
      borrower: user?.entities?.peopleId,
    });
    const { values: relatedPeople = [] } = await Dynamicore(SERVICES.PEOPLE_ORGANIZATIONAL).get({
      borrower: user?.entities?.peopleId,
      limit: 1000,
      page: 1,
    });

    relationships.forEach((item) => {
      item.people = relatedPeople
        .find((relatedItem) => relatedItem.id === item.person);
      delete item.person;
    });

    user.data.relationships = relationships;
  }

  return user;
};

export default getUserInfo;
